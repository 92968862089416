import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";
import { useMediaQuery } from "react-responsive";

import MDTypography from "components/MDTypography";
import { Avatar, Card, CardContent, CardMedia } from "@mui/material";
// import carouselImages from "./carouselImage.jpg";

import { getMyEventsDetails } from "services/contract";
import { getOtherEventsDetails } from "services/contract";
import { getIpfsData } from "services/contract";
import { getUpdatedPrice } from "services/contract";
import { useWallet } from "store/wallet";
import test1 from "./1.png";
import SkeletonLoader from "skeletonLoader";
import SinghCoin from "./SinghCoin.png";
import { useNavigate } from "react-router-dom";
import MDAvatar from "components/MDAvatar";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import Slider from "../SinghDashboard/banner";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import { getMyEventList } from "services/contract";
import Swal from "sweetalert2";
import { getMyEvents } from "services/contract";

export default function MyEvents() {
  let navigate = useNavigate();
  const { web3, isWeb3Enabled, address } = useWallet();
  const [myEvents, setMyEvents] = useState([]);
  const [myEventsLoading, setMyEventsLoading] = useState(true);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4,
    },
    laptop: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 330 },
      items: 1,
    },
    mobileSmall: {
      breakpoint: { max: 320, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  useEffect(() => {
    eventsCallHandler();
  }, [address]);

  const eventsCallHandler = async () => {
    // if (!isWeb3Enabled) {
    //   // console.log("pls connect wallet");
    //   Swal.fire({
    //     icon: "warning",
    //     text: "Please connect your Metamask Wallet",
    //   }).then(() => {
    //     navigate(`/`);
    //   });

    //   return;
    // }

    // const chainId = await web3.eth.getChainId();

    // if (chainId !== 97) {
    //   // console.log("pls change network");

    //   Swal.fire("", "Please switch your metamask network to BNB Smartchain Testnet Network");
    //   return;
    // }

    // if (address) {
    // fetchMyEventsDetails();
    fetchMyEventsLists();
    // } else console.log("connectMetamask");
  };

  const fetchMyEventsLists = async () => {
    setMyEventsLoading(true);
    const myEventsList = await getMyEvents(address, 0);

    console.log("myEvents", myEventsList);

    setMyEvents(myEventsList);
    setMyEventsLoading(false);
  };

  // const fetchMyEventsDetails = async () => {
  //   setMyEventsLoading(true);
  //   const myEvents = await getMyEventsDetails(address);
  //   for (let event of myEvents) {
  //     if (event.returnValues.metaUri) {
  //       const myEventsData = await getIpfsData(event.returnValues.metaUri);
  //       const updatedPrice = await getUpdatedPrice(web3, event.returnValues.tokenId);
  //       event.returnValues["name"] = myEventsData.name;
  //       event.returnValues["category"] = myEventsData.category;
  //       event.returnValues["description"] = myEventsData.description;
  //       event.returnValues["photo"] = myEventsData.photo;
  //       event.returnValues["venue"] = myEventsData.venue;
  //       event.returnValues["blockChain"] = myEventsData.blockChain;
  //       event.returnValues["latestPrice"] = updatedPrice;
  //     }
  //   }
  //   setMyEvents(myEvents);
  //   setMyEventsLoading(false);
  // };

  const handleEventDetails = (name, item) => {
    item["eventDetails"] = "myEvent";
    // navigate("/event-details", { state: item });
    navigate(`/event-details/?eventDetails=${name}&tokenId=${item.tokenId}`);
  };

  const handleSeeAllEvents = (name) => {
    console.log("name", name);
    // item["eventDetails"] = "otherEvent";
    navigate(`/explore/?status=${name}`);
  };

  return (
    <>
      <DashboardNavbar />
      <Slider />

      <MDBox
        className="MyEventsSlider"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: isTabletOrMobile ? "5%" : "10%",
          marginLeft: isTabletOrMobile ? "5%" : "10%",
          marginBottom: "2rem",
          alignItems: "center",
        }}
      >
        <MDBox>
          <MDTypography fontWeight="bold" variant="h3">
            Your Events
          </MDTypography>
        </MDBox>
        {/* <MDBox sx={{ cursor: "pointer" }}>
          {myEvents.length > 4 && <MDTypography variant="h6">See All</MDTypography>}
        </MDBox> */}
        <MDBox sx={{ cursor: "pointer" }}>
          <MDTypography variant="h6">
            {myEvents.length > 4 && (
              <MDButton
                name="My Events"
                color="button"
                variant="text"
                size="small"
                onClick={(e) => handleSeeAllEvents(e.target.name)}
              >
                See More
                <ArrowForwardIosIcon sx={{}} />
              </MDButton>
            )}
          </MDTypography>
        </MDBox>
      </MDBox>

      <MDBox
        className="recentEvents"
        sx={{
          marginBottom: "5rem",
          marginRight: isTabletOrMobile ? null : "8.5%",
          marginLeft: isTabletOrMobile ? null : "8.5%",
        }}
      >
        {myEvents && myEvents.length !== 0 ? (
          <Carousel responsive={responsive} infinite={true} itemClass="test">
            {myEvents.map((item, idx) => (
              <Card
                key={idx}
                sx={{
                  margin: 0,
                  cursor: "pointer",
                  // width: "275px",
                  height: "400px",
                  marginRight: "20px",
                  marginLeft: "20px",
                }}
                onClick={() => handleEventDetails("My Events", item)}
              >
                {/* <MDBox
                  sx={{
                    height: "350px",
                    borderRadius: "10px 10px 0px 0px",
                    border: "1px solid red",
                  }}
                >
                  <img src={item.returnValues.photo} width="100%" height="100%" alt="logo" />
                </MDBox> */}
                <CardMedia
                  sx={{ margin: 0, borderRadius: "10px 10px 0px 0px" }}
                  component="img"
                  height="360"
                  image={item.photo}
                  alt="Paella dish"
                />
                <MDBox
                  sx={{
                    position: "absolute",
                    top: 10,
                    marginLeft: "5px",
                    width: "90px",
                    backgroundColor: "#000000",
                    opacity: "60%",
                    borderRadius: "15px",
                    textAlign: "center",
                  }}
                >
                  <MDTypography fontSize="small">{item.eventDate}</MDTypography>
                </MDBox>

                <MDBox sx={{ padding: 1 }}>
                  <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                    <MDBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {/* <Avatar src={profileUser} size="xs" /> */}
                      <MDBox marginLeft="4px" width="120px">
                        <MDTypography fontWeight="bold" fontSize="13px">
                          {item.eventName}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox
                      sx={{
                        display: "flex",
                        // textAlign: "center",
                        alignItems: "center",
                        // alignContent: "center",
                      }}
                    >
                      <MDAvatar src={SinghCoin} size="xs" />
                      <MDTypography color="white" fontWeight="bold" fontSize="small">
                        {item.originalPrice === "0"
                          ? "Free"
                          : Math.round(Number(item.originalPrice) * 100) / 100}
                        {/* parseFloat(item.originalPrice).toFixed(4) */}
                      </MDTypography>

                      {/* <MDTypography fontSize="10px">Ticket Price</MDTypography> */}
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            ))}
          </Carousel>
        ) : (
          <MDBox>
            {myEventsLoading ? (
              <SkeletonLoader />
            ) : (
              <MDBox sx={{ textAlign: "center" }}>
                <MDTypography>There is no events</MDTypography>
              </MDBox>
            )}
          </MDBox>
        )}
      </MDBox>
      <Footer />
    </>
  );
}
