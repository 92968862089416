import React from "react";
import { useMediaQuery } from "react-responsive";
import Skeleton from "@mui/material/Skeleton";
import MDBox from "components/MDBox";

export default function SkeletonLoader() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <>
      <MDBox
        sx={{
          display: "flex",
          gap: "2.3rem",
          marginRight: "1rem",
          marginLeft: "1rem",
          //   marginRight: isTabletOrMobile ? null : "10rem",
          //   marginLeft: isTabletOrMobile ? null : "10rem",
        }}
      >
        <Skeleton
          sx={{
            bgcolor: "grey.900",
            borderRadius: "15px",
            width: { xs: 350, sm: 350, md: 280 },
          }}
          variant="rounded"
          height={400}
        />
        <Skeleton
          sx={{
            bgcolor: "grey.900",
            borderRadius: "15px",
            width: { xs: 350, sm: 350, md: 280 },
            display: { xs: "none", sm: "block", md: "block", lg: "block" },
          }}
          variant="rounded"
          height={400}
        />
        <Skeleton
          sx={{
            bgcolor: "grey.900",
            borderRadius: "15px",
            display: { xs: "none", sm: "none", md: "block", lg: "block" },
          }}
          variant="rounded"
          width={280}
          height={400}
        />
        <Skeleton
          sx={{
            bgcolor: "grey.900",
            borderRadius: "15px",
            display: { xs: "none", sm: "none", md: "none", lg: "block" },
          }}
          variant="rounded"
          width={280}
          height={400}
        />
      </MDBox>
    </>
  );
}
