import MDBox from "components/MDBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getIpfsData } from "services/contract";
import { getUpdatedPrice } from "services/contract";
// import { getOtherEventsDetails } from "services/contract";
import { useWallet } from "store/wallet";
import Slider from "../SinghDashboard/banner";
import { useMediaQuery } from "react-responsive";
import MDAvatar from "components/MDAvatar";
import { Card, CardMedia } from "@mui/material";
import SinghCoin from "./SinghCoin.png";
import MDTypography from "components/MDTypography";
import Loader from "loader/loader";
import Footer from "examples/Footer";
import { getMyEventList } from "services/contract";
import { getAllEventList } from "services/contract";
import { getMyEvents } from "services/contract";
import { getEventsByStatus } from "services/contract";
import { useSearchParams } from "react-router-dom";
// import { getMyEventsDetails } from "services/contract";

export default function SeeAllEvents() {
  let navigate = useNavigate();
  let location = useLocation();
  const [searchParams] = useSearchParams();
  const { web3, isWeb3Enabled, address } = useWallet();
  const [otherEventsLoading, setOtherEventsLoading] = useState(true);
  const [myEventsLoading, setMyEventsLoading] = useState(true);
  const [otherEvents, setOtherEvents] = useState([]);
  const [myEvents, setMyEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  let pageNumber = 0;

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const handleScroll = (e) => {
    if (
      window.innerHeight + e.target.documentElement.scrollTop + 1 >=
      e.target.documentElement.scrollHeight
    ) {
      // console.log("we at bottom");

      eventsCallHandler();
    }
  };

  console.log(searchParams.get("status"));

  useEffect(() => {
    eventsCallHandler();
  }, [address]);

  const eventsCallHandler = () => {
    if (address) {
      if (searchParams.get("status") === "My Events") {
        fetchMyEventsLists();
        window.addEventListener("scroll", handleScroll);
      } else {
        fetchOtherEventsDetails();
        window.addEventListener("scroll", handleScroll);
      }
    } else console.log("connectMetamask");
  };

  const fetchMyEventsLists = async () => {
    setMyEventsLoading(true);
    // console.log("Numbe2", pageNumber);
    const myEvents = await getMyEvents(address, pageNumber);
    // console.log("myE", myEvents);
    if (myEvents.length > 0) {
      pageNumber = pageNumber + 1;
    } else {
      null;
    }
    setFilteredEvents((filteredEvents) => [...filteredEvents, ...myEvents]);

    setMyEventsLoading(false);
  };

  const fetchOtherEventsDetails = async () => {
    setOtherEventsLoading(true);
    let status;
    if (searchParams.get("status") === "Ongoing Events") {
      status = "ongoing";
    } else if (searchParams.get("status") === "Upcoming Events") {
      status = "upcoming";
    } else if (searchParams.get("status") === "Past Events") {
      status = "past";
    }
    console.log("numb", pageNumber);
    const otherEvents = await getEventsByStatus(status, pageNumber);
    console.log("other", otherEvents);
    if (otherEvents.length > 0) {
      pageNumber = pageNumber + 1;
    } else {
      null;
    }
    setFilteredEvents((filteredEvents) => [...filteredEvents, ...otherEvents]);

    setOtherEventsLoading(false);
  };

  const handleEventDetails = (name, item) => {
    // item["eventDetails"] = "myEvent";
    // navigate("/event-details", { state: item });
    navigate(`/event-details/?eventDetails=${name}&tokenId=${item.tokenId}`);
  };

  // console.log(otherEvents);
  // console.log("filter", filteredEvents);

  // console.log("loc", location.state);
  return (
    <>
      <DashboardNavbar />
      <Slider />

      <MDBox
        sx={{
          marginRight: isTabletOrMobile ? "5%" : "10%",
          marginLeft: isTabletOrMobile ? "5%" : "10%",
          marginBottom: "2rem",
        }}
      >
        <MDTypography variant="h3">{searchParams.get("status")}</MDTypography>
      </MDBox>

      <MDBox
        className="recentEventsHeader"
        sx={{
          // border: "1px solid red",
          display: "flex",
          gap: "2.5rem",
          justifyContent: "center",
          flexWrap: "wrap",
          marginRight: isTabletOrMobile ? "5%" : "10%",
          marginLeft: isTabletOrMobile ? "5%" : "10%",
          //   marginBottom: "2rem",
          //   alignItems: "center",
        }}
      >
        {filteredEvents && filteredEvents.length !== 0 ? (
          <>
            {filteredEvents.map((item, idx) => (
              <Card
                sx={{
                  margin: 0,
                  cursor: "pointer",
                  width: "260px",
                  height: "400px",
                  //   marginRight: "20px",
                  //   marginLeft: "20px",
                }}
                // onClick={() => handleEventDetails("myEvent", item)}
                onClick={() => handleEventDetails(searchParams.get("status"), item)}
              >
                {/* <MDBox
                  sx={{
                    height: "350px",
                    borderRadius: "10px 10px 0px 0px",
                    border: "1px solid red",
                  }}
                >
                  <img src={item.returnValues.photo} width="100%" height="100%" alt="logo" />
                </MDBox> */}
                <CardMedia
                  sx={{ margin: 0, borderRadius: "10px 10px 0px 0px" }}
                  component="img"
                  height="360"
                  image={item.photo}
                  alt="Paella dish"
                />
                <MDBox
                  sx={{
                    position: "absolute",
                    top: 10,
                    marginLeft: "5px",
                    width: "90px",
                    backgroundColor: "#000000",
                    opacity: "60%",
                    borderRadius: "15px",
                    textAlign: "center",
                  }}
                >
                  <MDTypography fontSize="small">{item.eventDate}</MDTypography>
                </MDBox>

                <MDBox sx={{ padding: 1 }}>
                  <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                    <MDBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {/* <Avatar src={profileUser} size="xs" /> */}
                      <MDBox marginLeft="4px" width="120px">
                        <MDTypography fontWeight="bold" fontSize="13px">
                          {item.eventName}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox
                      sx={{
                        display: "flex",
                        // textAlign: "center",
                        alignItems: "center",
                        // alignContent: "center",
                      }}
                    >
                      <MDAvatar src={SinghCoin} size="xs" />
                      <MDTypography color="white" fontWeight="bold" fontSize="small">
                        {item.originalPrice === "0"
                          ? "Free"
                          : Math.round(Number(item.originalPrice) * 100) / 100}
                        {/* parseFloat(item.originalPrice).toFixed(4) */}
                      </MDTypography>

                      {/* <MDTypography fontSize="10px">Ticket Price</MDTypography> */}
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            ))}
          </>
        ) : (
          <MDBox
            sx={{
              // border: "1px solid red",
              // height: "500px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader />
          </MDBox>
        )}
      </MDBox>
      <Footer />
    </>
  );
}
