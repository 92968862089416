import {
  Avatar,
  ButtonGroup,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { FiMoreHorizontal } from "react-icons/fi";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import demoTicket from "./demoTicket.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { eventContractAddress } from "utils/config";
import { useWallet } from "store/wallet";
import { buyTickets } from "services/contract";
import { getBuyTicketDetails } from "services/contract";
import MDInput from "components/MDInput";
import createPostIMG from "assets/images/CreatePost.png";
import { getChangedPrice } from "services/contract";
import { useEffect } from "react";
import { getUpdatedPrice } from "services/contract";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import { date } from "yup/lib/locale";
import soldOutLogo from "./soldout.png";
import MDBadge from "components/MDBadge";
import profileUser from "./profileUser.png";
import { getUserTokenBalance } from "services/contract";
// import singhCoinLogo from "assets/images/favicon.png";
import singhCoinLogo from "./SinghCoin.png";
import Moment from "react-moment";
import Footer from "examples/Footer";
import { postBuyerDetails } from "services/contract";
import { addMail } from "services/contract";
import { useSearchParams } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import { addEventOrganisers } from "services/contract";
import { getEventOrganisers } from "services/contract";
import { deactivateEvent } from "services/contract";
import { FaRegCalendarAlt } from "react-icons/fa";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { MdOutlinePlace } from "react-icons/md";
import { MdOutlinePersonOutline } from "react-icons/md";
import { IoTicketOutline } from "react-icons/io5";
import { getEventsById } from "services/contract";
import DetailedLoader from "skeletonLoader/detailedLoader";

function EventDetails() {
  let navigate = useNavigate();
  let location = useLocation();
  const { web3, isWeb3Enabled, address } = useWallet();
  const [dialogBox, setDialogBox] = useState(false);
  const [dialogBoxPrice, setDialogBoxPrice] = useState(false);
  const [balanceNumOfTickets, setBalanceNumOfTickets] = useState("");
  const [ticketCount, setTicketCount] = useState(1);
  const [changePrice, setChangePrice] = useState("");
  const [updatedPrice, setUpdatedPrice] = useState("");
  const [loader, setLoader] = useState(false);
  const [walletBalance, setWalletBalance] = useState("");
  const [buyerEmail, setBuyerEmail] = useState("");
  const [eventOrgAdd, setEventOrgAdd] = useState("");
  const [eventOrgAddLoading, setEventOrgAddLoading] = useState(false);
  const [gotEventOrgAdd, setGotEventOrgAdd] = useState([]);
  const [eventAddBoolean, setEventAddBoolean] = useState(false);
  const [searchParams] = useSearchParams();
  const [pageLoading, setPageLoading] = useState(true);
  const [creator, setCreator] = useState("");
  const [tokenId, setTokenId] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventName, setEventName] = useState("");
  const [eventType, setEventType] = useState("");
  const [category, setCategory] = useState("");
  const [photo, setPhoto] = useState("");
  const [venue, setVenue] = useState("");
  const [description, setDescription] = useState("");
  const [originalPrice, setOriginalPrice] = useState("");
  const [remainTicket, setRemainTicket] = useState("");
  const [soldTicket, setSoldTicket] = useState("");
  const [EventStatus, setEventStatus] = useState("");

  // console.log("state", location.state);
  // const { eventDetails } = location.state;

  // const {
  //   creator,
  //   tokenId,
  //   eventDate,
  //   eventName,
  //   eventType,
  //   category,
  //   photo,
  //   venue,
  //   description,
  //   originalPrice,
  //   remainTicket,
  //   soldTicket,
  //   EventStatus,
  // } = location.state;

  useEffect(() => {
    if (Object.keys(web3).length != 0 && address != undefined) {
      fetchEventById();
    }

    // fetchUpdatedPrice();
    // fetchUpdatedNoOfTickets();
    // fetchEventOrganisers();
    // balance();
  }, [address, web3, loader, eventOrgAdd]);

  const fetchEventById = async () => {
    setPageLoading(true);
    const eventById = await getEventsById(searchParams.get("tokenId"));
    // console.log("BYID", eventById);

    setCreator(eventById[0].creator);
    setTokenId(eventById[0].tokenId);
    setEventDate(eventById[0].eventDate);
    setEventName(eventById[0].eventName);
    setEventType(eventById[0].eventType);
    setCategory(eventById[0].category);
    setPhoto(eventById[0].photo);
    setVenue(eventById[0].venue);
    setDescription(eventById[0].description);
    setOriginalPrice(eventById[0].originalPrice);
    setRemainTicket(eventById[0].remainTicket);
    setSoldTicket(eventById[0].soldTicket);
    setEventStatus(eventById[0].EventStatus);
    setPageLoading(false);
  };

  // const fetchEventOrganisers = async () => {
  //   if (Object.keys(web3).length != 0 && address != undefined) {
  //     const eventOrganisers = await getEventOrganisers(web3, tokenId);
  //     setGotEventOrgAdd(eventOrganisers);
  //   } else {
  //     console.log("errror for ORG");
  //   }
  // };

  // const balance = async () => {
  //   console.log("web3", web3);
  //   if (Object.keys(web3).length != 0 && address != undefined) {
  //     const walletBalance = await getUserTokenBalance(web3, address);

  //     console.log("walletBal", walletBalance);
  //     setWalletBalance(walletBalance.tokenBal);
  //   } else {
  //     console.log("web3empty", web3);
  //   }
  // };

  // const fetchUpdatedNoOfTickets = async () => {
  //   if (Object.keys(web3).length != 0 && address != undefined) {
  //     const availableTickets = await getBuyTicketDetails(web3, tokenId);
  //     console.log("availableTickets", availableTickets.noOfTickets);
  //     setBalanceNumOfTickets(availableTickets.noOfTickets);
  //   }
  // };

  // const fetchUpdatedPrice = async () => {
  //   if (Object.keys(web3).length != 0 && address != undefined) {
  //     const newPrice = await getUpdatedPrice(web3, tokenId);
  //     setUpdatedPrice(newPrice);
  //   }
  // };

  const handleEdit = () => {
    setDialogBoxPrice(true);
  };

  const handleBuyDialog = async () => {
    if (!isWeb3Enabled) {
      // console.log("pls connect wallet");
      Swal.fire("", "Please connect your Metamask Wallet");
      return;
    }

    const chainId = await web3.eth.getChainId();

    if (chainId !== 97) {
      // console.log("pls change network");

      Swal.fire("", "Please switch your metamask network to BNB Smartchain Testnet Network");
      return;
    }

    setDialogBox(true);
  };

  const handleCloseDialogBoxPrice = () => {
    setDialogBoxPrice(false);
  };

  const handleCloseDialogBox = () => {
    setDialogBox(false);
  };

  const handleBuy = async (e) => {
    e.preventDefault();
    setLoader(true);

    const amount = originalPrice * ticketCount;

    const buyTicketsResponse = await buyTickets(web3, address, amount, tokenId, ticketCount);

    if (buyTicketsResponse.blockHash) {
      // 2 apicall will merge

      const buyerDetails = {
        // creator: creator,
        email: buyerEmail,
        tokenId: tokenId,
        noOftickets: ticketCount,
        price: amount,
        buyerAdd: address,
        transactionHash: buyTicketsResponse.transactionHash,
      };
      const postBuyerDetailsRes = await postBuyerDetails(buyerDetails);
      console.log("postBuyerRes", postBuyerDetailsRes);

      setLoader(false);
      setDialogBox(false);
      Swal.fire({
        icon: "success",
        text: "Ticket bought successfully",
      });
    } else {
      setLoader(false);
      setDialogBox(false);
      Swal.fire({
        icon: "error",
        text: "Ticket buying failed",
      });
    }
  };

  const handleTicketCountAdd = () => {
    if (ticketCount < remainTicket) {
      setTicketCount((prev) => prev + 1);
    } else {
      console.log("valueErr");
    }
  };

  const handleTicketCountMinus = () => {
    if (ticketCount > 1) {
      console.log("count", ticketCount);
      setTicketCount((prev) => prev - 1);
    } else {
      console.log("valueErr");
    }
  };

  const handleChangePriceInput = (value) => {
    console.log("value", value);
    setChangePrice(value);
  };

  const handleChangePrice = async (e) => {
    e.preventDefault();
    setLoader(true);

    const changedPrice = await getChangedPrice(web3, address, tokenId, changePrice);
    console.log("changedPrice", changedPrice);
    setLoader(false);
    setDialogBoxPrice(false);
    Swal.fire({
      icon: "success",
      text: "Price changed successfully",
    });
    setChangePrice("");
  };

  // const handleCancelChangePrice = () => {
  //   setDialogBoxPrice(false);
  // };

  // const handleCancelBuy = () => {
  //   setDialogBox(false);
  // };

  const handleViewSales = () => {
    /// creatoraddress for details
    navigate(`/order-details/?creatorAddress=${creator}&tokenId=${tokenId}`);
  };

  const handleAddOrganisers = async () => {
    if (gotEventOrgAdd.includes(eventOrgAdd)) {
      Swal.fire("", "Given wallet address is already an organiser", "warning");

      console.log("exist");
      return;
    }

    setEventOrgAddLoading(true);
    const addOrgRes = await addEventOrganisers(web3, tokenId, eventOrgAdd, address);
    console.log("addORG", addOrgRes);

    if (addOrgRes.blockHash) {
      Swal.fire("", "Event organiser added", "success");
    } else {
      Swal.fire("", "Incorrect wallet address", "warning");
    }
    setEventOrgAdd("");
    setEventOrgAddLoading(false);
  };

  // const handleDeactivate = async () => {
  //   const eventData = {
  //     walletAddress: address,
  //     tokenId: tokenId,
  //   };
  //   const eventDeactivate = await deactivateEvent(eventData);

  //   if (eventDeactivate.success === true) {
  //     Swal.fire("", "Event deactivated successfully", "success");
  //   }

  //   console.log("deactivateRes", eventDeactivate);
  // };

  return (
    <>
      <DashboardNavbar />

      {pageLoading ? (
        <>
          <DetailedLoader />
        </>
      ) : (
        <MDBox>
          <MDBox
            sx={{
              marginBottom: "3rem",
              height: { xs: "none", md: "500px" },
              width: "100%",
              backgroundImage: `url(${photo})`,
              objectFit: "contain",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Grid
              container
              sx={{
                height: "100%",
                backgroundImage:
                  "linear-gradient(90deg,#1a1a1a,rgba(18, 18, 18, 0.9),rgba(18, 18, 18, 0.8),rgba(18, 18, 18, 0.9),rgba(18, 18, 18, 0.9), #1a1a1a)",
              }}
            >
              <Grid
                item
                container
                xs={12}
                md={4}
                sx={{
                  height: "100%",
                  // marginBottom: { xs: "1rem", md: "none" },
                  padding: "0 20px 0 0",
                  justifyContent: "right",
                  alignItems: "center",
                }}
              >
                <MDBox sx={{ height: "400px", width: "350px" }}>
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "10px 10px 10px 10px",
                    }}
                    src={photo}
                    alt="logo"
                  />
                </MDBox>
              </Grid>

              <Grid
                item
                container
                xs={12}
                md={5}
                sx={{
                  paddingLeft: "1rem",
                  // paddingRight: { xs: "1rem", md: "none" },
                  // paddingLeft: { xs: "1rem", md: "none" },
                  alignItems: "center",
                }}
              >
                <MDBox sx={{}}>
                  <MDBox sx={{ width: "100%" }}>
                    <MDBox>
                      <MDTypography fontWeight="bold" sx={{ fontSize: { xs: "30px", md: "20px" } }}>
                        {category} &nbsp; || &nbsp; {remainTicket} tickets remaining
                      </MDTypography>
                    </MDBox>
                    <MDBox>
                      <MDTypography fontWeight="bold" sx={{ fontSize: { xs: "30px", md: "40px" } }}>
                        {eventName}
                      </MDTypography>
                    </MDBox>
                    <MDBox>
                      <MDTypography
                        fontWeight="regular"
                        sx={{ fontSize: { xs: "20px", md: "30px" } }}
                      >
                        <FaRegCalendarAlt size={20} />
                        &nbsp;
                        <Moment format="ddd,DD MMMM, YYYY">{new Date(eventDate)}</Moment>
                      </MDTypography>
                    </MDBox>
                    <MDBox>
                      <MDTypography
                        fontWeight="regular"
                        sx={{ fontSize: { xs: "20px", md: "30px" } }}
                      >
                        <MdOutlinePlace size={20} />
                        &nbsp;
                        {venue}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox
                    sx={{
                      marginBottom: "2rem",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <MDBox sx={{ marginLeft: "-5px", height: 30, width: 30 }}>
                      <img src={singhCoinLogo} style={{ width: "100%", height: "100%" }} />
                    </MDBox>

                    <MDTypography fontSize="30px">
                      {originalPrice === "0" ? "Free" : originalPrice}
                    </MDTypography>
                  </MDBox>
                  <MDBox sx={{ width: "100%", display: "flex", gap: "1rem" }}>
                    {/* <MDButton>
                  <MdDeleteOutline />
                </MDButton> */}

                    {searchParams.get("eventDetails") === "My Events" ? (
                      <MDButton
                        size="meduim"
                        disabled={
                          new Date(eventDate) < new Date() ||
                          remainTicket === "0" ||
                          originalPrice === "0"
                        }
                        onClick={() => handleEdit()}
                      >
                        {new Date(eventDate) > new Date() ? (
                          remainTicket === "0" ? (
                            "Sold Out"
                          ) : (
                            <>
                              <MdOutlineModeEditOutline />
                              &nbsp; Edit
                            </>
                          )
                        ) : (
                          "Ended"
                        )}
                      </MDButton>
                    ) : (
                      <MDButton
                        size="meduim"
                        disabled={new Date(eventDate) < new Date() || remainTicket === "0"}
                        onClick={() => handleBuyDialog()}
                      >
                        {/* {console.log("frm data", new Date(eventDate))}
                    {console.log("compare to today", new Date())} */}
                        {new Date(eventDate) > new Date() ? (
                          remainTicket === "0" ? (
                            "Sold Out"
                          ) : (
                            <>
                              <IoTicketOutline />
                              &nbsp; Buy Ticket
                            </>
                          )
                        ) : (
                          "Ended"
                        )}
                      </MDButton>
                    )}

                    {searchParams.get("eventDetails") === "My Events" ? (
                      <MDButton size="meduim" onClick={() => handleViewSales()}>
                        <MdOutlinePersonOutline />
                        &nbsp; ATTENDEES
                      </MDButton>
                    ) : null}
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>

          <Grid
            container
            sx={{
              marginBottom: "2rem",
              // border: "1px solid red",
              // paddingLeft: "9rem",
              // paddingRight: "9rem",
              // marginBottom: "2rem",
              paddingLeft: { xs: "1rem", md: "8rem" },
              paddingRight: { xs: "1rem", md: "8rem" },
            }}
          >
            <Grid item container xs={12} sx={{ flexDirection: "column" }}>
              <Grid item>
                <MDBox sx={{}}>
                  <MDTypography variant="h3">About the event</MDTypography>
                </MDBox>
              </Grid>
              <Grid item>
                <MDBox>
                  <MDTypography fontWeight="regular" variant="h5">
                    {description}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </Grid>

          <Divider />

          <Grid
            sx={{
              marginBottom: "5rem",
              // border: "1px solid red",
              // paddingLeft: "9rem",
              // paddingRight: "9rem",
              // marginBottom: "2rem",
              paddingLeft: { xs: "1rem", md: "8rem" },
              paddingRight: { xs: "1rem", md: "8rem" },
            }}
          >
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "0.5rem",
              }}
            >
              <MDTypography variant="h3">Other details</MDTypography>
              {searchParams.get("eventDetails") === "My Events" ? (
                <MDBox sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                  <MDButton size="medium" onClick={() => setEventAddBoolean(true)}>
                    Add organisers
                  </MDButton>
                </MDBox>
              ) : null}
            </MDBox>

            {searchParams.get("eventDetails") === "My Events" ? (
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "0.5rem",
                }}
              >
                <MDBox sx={{ width: "35%" }}>
                  {eventAddBoolean && (
                    <MDInput
                      fullWidth
                      onChange={(e) => setEventOrgAdd(e.target.value)}
                      placeholder="Add organiser wallet address"
                      value={eventOrgAdd}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton onClick={() => handleAddOrganisers()}>
                              <MDTypography color="button">
                                {eventOrgAddLoading ? (
                                  <CircularProgress color="inherit" size={25} />
                                ) : (
                                  <AddIcon />
                                )}
                              </MDTypography>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </MDBox>
              </MDBox>
            ) : null}

            <MDBox sx={{ display: "flex", marginBottom: "0.5rem" }}>
              <MDTypography fontWeight="regular" variant="h5">
                Venue :&nbsp;
              </MDTypography>
              <MDTypography fontWeight="light" variant="h5">
                {venue}
              </MDTypography>
            </MDBox>
            <MDBox
              sx={{
                display: "flex",
                flexWrap: "wrap",
                wordBreak: "break-word",
                marginBottom: "0.5rem",
              }}
            >
              <MDTypography fontWeight="regular" variant="h5">
                Creator :&nbsp;
              </MDTypography>
              <MDTypography fontWeight="light" variant="h5">
                {creator}
              </MDTypography>
            </MDBox>
            <MDBox sx={{ display: "flex", marginBottom: "0.5rem" }}>
              <MDTypography fontWeight="regular" variant="h5">
                Contract Address :&nbsp;
              </MDTypography>
              <MDTypography fontWeight="light" variant="h5">
                {eventContractAddress}
              </MDTypography>
            </MDBox>
            <MDBox sx={{ display: "flex", marginBottom: "0.5rem" }}>
              <MDTypography fontWeight="regular" variant="h5">
                Token Standard :&nbsp;
              </MDTypography>
              <MDTypography fontWeight="light" variant="h5">
                ERC115
              </MDTypography>
            </MDBox>
            <MDBox sx={{ display: "flex", marginBottom: "0.5rem" }}>
              <MDTypography fontWeight="regular" variant="h5">
                Token Id :&nbsp;
              </MDTypography>
              <MDTypography fontWeight="light" variant="h5">
                {tokenId}
              </MDTypography>
            </MDBox>
            <MDBox sx={{ display: "flex", marginBottom: "0.5rem" }}>
              <MDTypography fontWeight="regular" variant="h5">
                Blockchain :&nbsp;
              </MDTypography>
              <MDTypography fontWeight="light" variant="h5">
                Binance
              </MDTypography>
            </MDBox>
            {searchParams.get("eventDetails") === "My Events" ? (
              <MDBox sx={{ display: "flex", marginBottom: "0.5rem" }}>
                <MDTypography fontWeight="regular" variant="h5">
                  Organisers-List :&nbsp;
                </MDTypography>
                {gotEventOrgAdd.length > 0 ? (
                  gotEventOrgAdd.map((item, idx) => (
                    <MDTypography key={idx} fontWeight="light" variant="h5">
                      {item}
                    </MDTypography>
                  ))
                ) : (
                  <MDTypography fontWeight="light" variant="h5">
                    No Organisers added yet
                  </MDTypography>
                )}
              </MDBox>
            ) : null}
          </Grid>
        </MDBox>
      )}

      <Dialog
        fullWidth
        maxWidth="xs"
        open={dialogBoxPrice}
        onClose={() => handleCloseDialogBoxPrice()}
        // onClose={(_, reason) => {
        //   if (reason !== "backdropClick") {
        //     handleCloseDialogBoxPrice();
        //   }
        // }}
        sx={{
          // "& .MuiDialog-paper::-webkit-scrollbar": {
          //   display: "none",
          // },
          "& .MuiDialog-paper": {
            borderRadius: "25px",
          },
          marginLeft: "3%",
        }}
      >
        <form onSubmit={(e) => handleChangePrice(e)}>
          <MDBox sx={{ backgroundImage: `url(${createPostIMG})`, backgroundSize: "cover" }}>
            <DialogTitle>
              <MDTypography color="white" fontWeight="regular">
                Change Price
              </MDTypography>
            </DialogTitle>
            <DialogContent>
              <MDInput
                fullWidth
                placeholder="Enter Price"
                type="number"
                required
                name="changePrice"
                value={changePrice}
                onChange={(e) => handleChangePriceInput(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <MDButton type="submit" disabled={loader}>
                <MDTypography>
                  {loader ? <CircularProgress color="inherit" size={25} /> : "Confirm"}
                </MDTypography>
              </MDButton>
            </DialogActions>
          </MDBox>
        </form>
      </Dialog>

      <Dialog
        // sx={{ "& .MuiDialog-paper": { background: "rgb(242, 240, 254)" } }}
        fullWidth
        maxWidth="sm"
        open={dialogBox}
        onClose={() => handleCloseDialogBox()}
        // onClose={(_, reason) => {
        //   if (reason !== "backdropClick") {
        //     handleCloseDialogBox();
        //   }
        // }}
        sx={{
          // "& .MuiDialog-paper::-webkit-scrollbar": {
          //   display: "none",
          // },
          "& .MuiDialog-paper": {
            borderRadius: "25px",
          },
          marginLeft: "3%",
        }}
      >
        <form onSubmit={(e) => handleBuy(e)}>
          <MDBox sx={{ backgroundImage: `url(${createPostIMG})`, backgroundSize: "cover" }}>
            <DialogContent sx={{ display: "flex", justifyContent: "space-between" }}>
              <MDBox>
                <MDTypography color="white">Available Tickets:</MDTypography>
                <MDTypography color="white">Quantity:</MDTypography>
                <MDTypography color="white">Price:</MDTypography>
                <MDTypography sx={{ marginBottom: "2rem" }} color="white">
                  Email Id:
                </MDTypography>

                <MDTypography color="white" fontWeight="bold">
                  Total Amount:
                </MDTypography>
              </MDBox>
              <MDBox sx={{ textAlign: "right" }}>
                <MDTypography color="white">{remainTicket}</MDTypography>
                <ButtonGroup size="small" sx={{ borderRadius: "15px", gap: "0.5rem" }}>
                  <IconButton
                    size="small"
                    sx={{ backgroundColor: "#FF9607", color: "#ffff" }}
                    onClick={() => handleTicketCountMinus()}
                  >
                    <RemoveIcon fontSize="small" />
                  </IconButton>

                  <MDTypography color="white" sx={{ alignSelf: "center" }} fontSize="small">
                    {ticketCount}
                  </MDTypography>

                  <IconButton
                    size="small"
                    sx={{ backgroundColor: "#FF9607", color: "#ffff" }}
                    onClick={() => handleTicketCountAdd()}
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                </ButtonGroup>
                <MDTypography color="white">{originalPrice}</MDTypography>
                <MDInput
                  type="email"
                  placeholder="Enter email"
                  required
                  sx={{ marginBottom: "2rem" }}
                  onChange={(e) => setBuyerEmail(e.target.value)}
                />
                <MDTypography color="white" fontWeight="bold">
                  {originalPrice * ticketCount}
                </MDTypography>
              </MDBox>
            </DialogContent>
            <DialogActions>
              <MDButton
                disabled={loader}
                type="submit"
                // onClick={() => handleBuy()}
              >
                <MDTypography>
                  {loader ? <CircularProgress color="inherit" size={25} /> : "Buy Now"}
                </MDTypography>
              </MDButton>
            </DialogActions>
          </MDBox>
        </form>
      </Dialog>
      <Footer />
    </>
  );
}

export default EventDetails;

{
  /* <Grid item container sx={{}}>
              <Grid item container xs={6} sx={{}}>
                <Grid item xs={12} sx={{}}>
                  <MDTypography
                    fontWeight="bold"
                    sx={{ fontSize: { xs: "30px", md: "40px" }, lineHeight: "50px" }}
                  >
                    {eventName}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sx={{}}>
                  <MDTypography fontWeight="regular" sx={{ fontSize: { xs: "20px", md: "30px" } }}>
                    <FaRegCalendarAlt />
                    &nbsp;
                    <Moment format="ddd,DD MMMM, YYYY">{new Date(eventDate)}</Moment>
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", flexWrap: "wrap" }}>
                  <MDTypography fontWeight="regular" sx={{ fontSize: { xs: "20px", md: "25px" } }}>
                    Available Tickets :&nbsp;
                  </MDTypography>
                  <MDTypography fontWeight="regular" sx={{ fontSize: { xs: "20px", md: "25px" } }}>
                    {remainTicket}
                  </MDTypography>
                </Grid>
                <Grid item xs={12}>
                  <MDTypography fontWeight="regular" sx={{ fontSize: { xs: "20px", md: "25px" } }}>
                    {category}
                  </MDTypography>
                </Grid>
              </Grid>
              <Grid item container xs={6} sx={{}}>
                <Grid item xs={12} sx={{ alignItems: "start" }}>
                  <MDBox
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <img src={singhCoinLogo} width="40px" height="40px" alt="singhCoinLogo" />
                    <MDBox sx={{ wordBreak: "break-word" }}>
                      <MDTypography sx={{ fontSize: { xs: "20px", md: "25px" } }}>
                        {originalPrice === "0" ? "Free" : originalPrice}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
                <Grid item xs={12} alignSelf="end" textAlign="end">
                  {eventDetails === "myEvent" ? (
                    <MDButton
                      size="large"
                      disabled={
                        new Date(eventDate) < new Date() ||
                        remainTicket === "0" ||
                        originalPrice === "0"
                      }
                      onClick={() => handleEdit()}
                    >
                      {new Date(eventDate) > new Date()
                        ? remainTicket === "0"
                          ? "Sold Out"
                          : "Edit"
                        : "Ended"}
                    </MDButton>
                  ) : (
                    <MDButton
                      size="large"
                      disabled={new Date(eventDate) < new Date() || remainTicket === "0"}
                      onClick={() => handleBuyDialog()}
                    >
                      {new Date(eventDate) > new Date()
                        ? remainTicket === "0"
                          ? "Sold Out"
                          : "Buy"
                        : "Ended"}
                    </MDButton>
                  )}
                </Grid>
              </Grid>
            </Grid> */
}
