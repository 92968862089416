import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useMediaQuery } from "react-responsive";
import Banner from "./banner";
import MDTypography from "components/MDTypography";
import { Avatar, Card, CardContent, CardMedia } from "@mui/material";
import "./carousel.css";
// import { getOtherEventsDetails } from "services/contract";
import { getIpfsData } from "services/contract";
import { getUpdatedPrice } from "services/contract";
import { useWallet } from "store/wallet";
import SkeletonLoader from "skeletonLoader";
import SinghCoin from "./SinghCoin.png";
import { useNavigate } from "react-router-dom";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getAllEventList } from "services/contract";
import { getEventsByStatus } from "services/contract";

export default function PastEvents() {
  let navigate = useNavigate();
  const { web3, isWeb3Enabled, address } = useWallet();
  const [otherEvents, setOtherEvents] = useState([]);
  const [otherEventsLoading, setOtherEventsLoading] = useState(true);
  const [pastEvents, setPastEvents] = useState([]);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4,
    },
    laptop: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 330 },
      items: 1,
    },
    mobileSmall: {
      breakpoint: { max: 320, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  useEffect(() => {
    eventsCallHandler();
  }, [address]);

  const eventsCallHandler = () => {
    // if (address) {
    // fetchOtherEventsDetails();
    fetchAllEventsLists();
    // } else console.log("connectMetamask");
  };

  const fetchAllEventsLists = async () => {
    setOtherEventsLoading(true);
    const otherEvents = await getEventsByStatus("past", "0");
    console.log("pastList", otherEvents);
    setPastEvents(otherEvents);
    // const pastEventsFunc = (item) => {
    //   if (new Date(item.eventDate) < new Date()) {
    //     return item;
    //   }
    // };
    // setPastEvents(otherEvents.filter(pastEventsFunc));
    setOtherEventsLoading(false);
  };

  // const fetchOtherEventsDetails = async () => {
  //   setOtherEventsLoading(true);
  //   const otherEvents = await getOtherEventsDetails();
  //   for (let event of otherEvents) {
  //     if (event.returnValues.metaUri) {
  //       const otherEventsData = await getIpfsData(event.returnValues.metaUri);
  //       const updatedPrice = await getUpdatedPrice(web3, event.returnValues.tokenId);
  //       event.returnValues["name"] = otherEventsData.name;
  //       event.returnValues["category"] = otherEventsData.category;
  //       event.returnValues["description"] = otherEventsData.description;
  //       event.returnValues["photo"] = otherEventsData.photo;
  //       event.returnValues["venue"] = otherEventsData.venue;
  //       event.returnValues["blockChain"] = otherEventsData.blockChain;
  //       event.returnValues["latestPrice"] = updatedPrice;
  //     }
  //   }
  //   setOtherEvents(otherEvents);
  //   const pastEventsFunc = (item) => {
  //     if (new Date(item.returnValues.eventDate) < new Date()) {
  //       return item;
  //     }
  //   };
  //   setPastEvents(otherEvents.filter(pastEventsFunc));
  //   setOtherEventsLoading(false);
  // };

  const handleEventDetails = (name, item) => {
    item["eventDetails"] = "otherEvent";
    // navigate("/event-details", { state: item });
    navigate(`/event-details/?eventDetails=${name}&tokenId=${item.tokenId}`);
  };

  const handleSeeAllEvents = (name) => {
    // console.log("name", name);
    // item["eventDetails"] = "otherEvent";
    navigate(`/explore/?status=${name}`);
  };

  return (
    <>
      <MDBox
        className="pastEventsHeader"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: isTabletOrMobile ? "5%" : "10%",
          marginLeft: isTabletOrMobile ? "5%" : "10%",
          marginBottom: "2rem",
          alignItems: "center",
        }}
      >
        <MDBox>
          <MDTypography fontWeight="bold" variant="h3">
            Past Events
          </MDTypography>
        </MDBox>
        <MDBox sx={{ cursor: "pointer" }}>
          <MDTypography variant="h6">
            {pastEvents.length > 4 && (
              <MDButton
                name="Past Events"
                color="button"
                variant="text"
                size="small"
                onClick={(e) => handleSeeAllEvents(e.target.name)}
              >
                See All
                <ArrowForwardIosIcon sx={{}} />
              </MDButton>
            )}
          </MDTypography>
        </MDBox>
      </MDBox>

      <MDBox
        className="pastEvents"
        sx={{
          marginBottom: "5rem",
          marginRight: isTabletOrMobile ? null : "8.5%",
          marginLeft: isTabletOrMobile ? null : "8.5%",
        }}
      >
        {pastEvents && pastEvents.length !== 0 ? (
          <Carousel responsive={responsive} infinite={true} itemClass="test">
            {pastEvents.map((item, idx) => (
              <Card
                key={idx}
                sx={{
                  margin: 0,
                  cursor: "pointer",
                  // width: "275px",
                  height: "400px",
                  marginRight: "20px",
                  marginLeft: "20px",
                }}
                onClick={() => handleEventDetails("otherEvent", item)}
                // onClick={() => handleData(item)}
              >
                {/* <CardMedia
                  sx={{ margin: 0, borderRadius: "10px 10px 0px 0px" }}
                  component="img"
                  height="360"
                  image={item.photo}
                  alt="Paella dish"
                ></CardMedia> */}
                <MDBox sx={{ height: "360px" }}>
                  <img
                    src={item.photo}
                    style={{ borderRadius: "10px 10px 0px 0px", height: "100%", width: "100%" }}
                  />
                </MDBox>
                <MDBox
                  sx={{
                    position: "absolute",
                    top: 10,
                    marginLeft: "5px",
                    width: "90px",
                    backgroundColor: "#000000",
                    opacity: "60%",
                    borderRadius: "15px",
                    textAlign: "center",
                  }}
                >
                  <MDTypography fontSize="small">{item.eventDate}</MDTypography>
                </MDBox>

                <MDBox sx={{ padding: 1 }}>
                  <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                    <MDBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {/* <Avatar src={profileUser} size="xs" /> */}
                      <MDBox marginLeft="4px" width="120px">
                        <MDTypography fontWeight="bold" fontSize="12px">
                          {item.eventName}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox
                      sx={{
                        display: "flex",
                        // textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <MDTypography color="white" fontWeight="bold" fontSize="small">
                        {item.originalPrice === "0"
                          ? "Free"
                          : Math.round(Number(item.originalPrice) * 100) / 100}
                        {/* parseFloat(item.originalPrice).toFixed(2) */}
                      </MDTypography>

                      <MDAvatar src={SinghCoin} size="xs" />
                      {/* <MDTypography fontSize="10px">Ticket Price</MDTypography> */}
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            ))}
          </Carousel>
        ) : (
          <MDBox>
            {otherEventsLoading ? (
              <SkeletonLoader />
            ) : (
              <MDBox sx={{ textAlign: "center" }}>
                <MDTypography>There is no events</MDTypography>
              </MDBox>
            )}
          </MDBox>
        )}
      </MDBox>
    </>
  );
}
