/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useCallback } from "react";

// react-router components
import { useLocation, useNavigate, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import MDButton from "components/MDButton";
import { useWallet } from "store/wallet";
import { FaWallet } from "react-icons/fa";
import { getUserTokenBalance } from "services/contract";
import singhCoinLogo from "assets/images/favicon.png";
import MDInput from "components/MDInput";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { Input } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDTypography from "components/MDTypography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Swal from "sweetalert2";
import { getSearch } from "services/contract";
import LockIcon from "@mui/icons-material/Lock";
import { IoTicketOutline } from "react-icons/io5";
import { TbCalendarStats } from "react-icons/tb";
import { HiOutlineViewGridAdd } from "react-icons/hi";

// import Swal from "sweetalert2";

function DashboardNavbar({ absolute, light, isMini }) {
  let navigate = useNavigate();
  const wallet = useWallet();
  const { web3, isWeb3Enabled, address } = useWallet();

  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const [walletAddress, setWalletAddress] = useState("Connect Wallet");
  const [walletBalance, setWalletBalance] = useState("0");

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const connectWallet = useCallback(
    async (api) => {
      // console.log("api", api);
      try {
        // console.log("connectWallet", wallet);
        await wallet.enableWeb3(api);
        // console.log("api", web3, isWeb3Enabled, address);
      } catch (err) {
        console.log("err", err);
      }
    },
    [wallet]
  );

  useEffect(() => {
    // checkConnectMetamask();
    balance();
  }, [address]);

  const balance = async () => {
    // console.log("web3", web3);
    if (Object.keys(web3).length != 0 && address != undefined) {
      const walletBalance = await getUserTokenBalance(web3, address);

      // console.log("walletBal", walletBalance);
      setWalletBalance(walletBalance.tokenBal);
    } else {
      console.log("web3empty", web3);
    }
  };

  // const checkConnectMetamask = async () => {
  //   if (!isWeb3Enabled) {
  //     console.log("pls connect wallet");

  //     Swal.fire("", "Please connect your Metamask Wallet");
  //     return;
  //   }

  //   const chainId = await web3.eth.getChainId();

  //   console.log("chaindid", chainId);

  //   if (chainId !== 56) {
  //     console.log("pls change network");

  //     Swal.fire("", "Please connect your metamask network to BNB Smartchain Mainnet Network");
  //     return;
  //   }
  // };

  // // Render the notifications menu
  // const renderMenu = () => (
  //   <Menu
  //     anchorEl={openMenu}
  //     anchorReference={null}
  //     anchorOrigin={{
  //       vertical: "bottom",
  //       horizontal: "left",
  //     }}
  //     open={Boolean(openMenu)}
  //     onClose={handleCloseMenu}
  //     sx={{ mt: 2 }}
  //   >
  //     <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
  //     <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
  //     <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
  //   </Menu>
  // );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const handleMyEvents = () => {
    navigate("/myevents");
  };

  const handleMyTickets = () => {
    navigate("/mytickets");
  };

  const handleCreateEvent = () => {
    navigate("/createvent");
  };

  const handleHome = () => {
    navigate("/");
  };

  // const handleSearch = async (name) => {
  //   const searchRes = await getSearch(name);
  //   console.log("searchRes", searchRes);
  // };

  const handleSearch = () => {
    navigate("/search");
  };

  return (
    <AppBar
      sx={{
        // marginBottom: "1rem",
        position: "sticky",
        marginBottom: "2rem",
        height: "70px",
        paddingLeft: isDesktopOrLaptop ? "8.5rem" : null,
        paddingRight: isDesktopOrLaptop ? "8.5rem" : null,
        backgroundColor: "#1a1a1a",
      }}
    >
      <Toolbar sx={{ gap: "0.6rem" }}>
        <MDBox
          color="white"
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => handleHome()}
        >
          <img src={singhCoinLogo} height="50px" />
          {/* <MDTypography color="button">Singh</MDTypography> */}
        </MDBox>
        <MDBox sx={{ flexGrow: "1" }}>
          {isDesktopOrLaptop ? (
            <MDInput
              autoComplete="off"
              sx={{
                width: "50%",
              }}
              placeholder="Search events..."
              onClick={(e) => handleSearch()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon sx={{ color: "#fff" }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <IconButton>
              <SearchIcon sx={{ color: "#FF9607" }} />
            </IconButton>
          )}
        </MDBox>

        {/* {isDesktopOrLaptop ? ( */}

        {/* <MDBox> */}
        <MDButton color="none" variant="outlined" size="small" sx={{}} disabled>
          <img src={singhCoinLogo} width="18px" style={{}} />
          <MDTypography fontSize="small" color="white">
            {walletBalance}
          </MDTypography>
        </MDButton>

        <MDButton
          variant="outlined"
          size="small"
          color="none"
          onClick={() => connectWallet("ethereum")}
          disabled={isWeb3Enabled}
        >
          <FaWallet style={{ marginRight: "5px", color: "#FF9607" }} />
          <MDTypography fontSize="small" color="white">
            {isWeb3Enabled && address !== undefined
              ? `${address.substring(0, 5)}...${address.substring(address.length - 5)}`
              : "Connect Wallet"}
          </MDTypography>
        </MDButton>
        {/* </MDBox> */}

        {/* ) : ( */}
        <MDBox>
          <IconButton onClick={(e) => handleClick(e)}>
            <AccountCircleIcon sx={{ color: "#fff" }} />
          </IconButton>

          <Menu
            disableScrollLock={true}
            id="admin-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            // transformOrigin={{
            //   vertical: "bottom",
            //   horizontal: "left",
            // }}
          >
            <MenuItem sx={{}} onClick={() => handleCreateEvent()}>
              <HiOutlineViewGridAdd />
              &nbsp; Create Event
            </MenuItem>
            <MenuItem sx={{}} onClick={() => handleMyEvents()} disabled={address === undefined}>
              {address ? <TbCalendarStats /> : <LockIcon />}&nbsp; Your Events
            </MenuItem>
            <MenuItem sx={{}} onClick={() => handleMyTickets()} disabled={address === undefined}>
              {address ? <IoTicketOutline /> : <LockIcon />}&nbsp; Your Tickets
            </MenuItem>
          </Menu>
        </MDBox>
        {/* )} */}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
