import React, { useEffect, useState } from "react";
import {
  Avatar,
  ButtonGroup,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { FiMoreHorizontal } from "react-icons/fi";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import demoTicket from "./demoTicket.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { eventContractAddress } from "utils/config";
import { useWallet } from "store/wallet";
import { buyTickets } from "services/contract";
import { getBuyTicketDetails } from "services/contract";
import MDInput from "components/MDInput";
import createPostIMG from "assets/images/CreatePost.png";
import { getChangedPrice } from "services/contract";
import { getUpdatedPrice } from "services/contract";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import { date } from "yup/lib/locale";
import soldOutLogo from "./soldout.png";
import MDBadge from "components/MDBadge";
import profileUser from "./profileUser.png";
import { getUserTokenBalance } from "services/contract";
import singhCoinLogo from "assets/images/favicon.png";
import Moment from "react-moment";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";
import MDDatePicker from "components/MDDatePicker";
import Validations from "customValidations/validations";
import ValidationService from "customValidations/validationService";
import { postMetaData } from "services/contract";
import { mint } from "services/contract";
import MDSelect from "components/MDSelect";
import Footer from "examples/Footer";
import { createEventApi } from "services/contract";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

let currentDate = new Date();
currentDate = `${currentDate.getFullYear()}-${
  currentDate.getMonth() + 1 <= 9 ? "0" + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1
}-${currentDate.getDate() <= 9 ? "0" + currentDate.getDate() : currentDate.getDate()}`;

export default function CreateEvent() {
  const { web3, isWeb3Enabled, address } = useWallet();

  const [errorMsg, setErrorMsg] = useState({
    name: { errorMsg: "" },
    venue: { errorMsg: "" },
    description: { errorMsg: "" },
    numberOfTickets: { errorMsg: "" },
    ticketPrice: { errorMsg: "" },
    date: { errorMsg: "" },
    category: { errorMsg: "" },
  });
  const [loader, setLoader] = useState(false);
  const [ticketType, setTicketType] = useState("Free");
  const [name, setName] = useState("");
  const [eventType, setEventType] = useState("Offline");
  const [description, setDescription] = useState("");
  const [numberOfTickets, setNumberOfTickets] = useState("");
  const [category, setCategory] = useState("");
  const [date, setDate] = useState(currentDate);
  const [venue, setVenue] = useState("");
  const [venueDetails, setVenueDetails] = useState("");
  const [ticketPrice, setTicketPrice] = useState(0);
  const [img, setImg] = useState("");
  const [imgSrc, setImgSrc] = useState("");

  const categoryList = [
    { label: "Business", value: "Business" },
    { label: "Food & drink", value: "Food & drink" },
    { label: "Health", value: "Health" },
    { label: "Music", value: "Music" },
    { label: "Auto, boat & air", value: "Auto, boat & air" },
    { label: "Charity & causes", value: "Charity & causes" },
    { label: "Community", value: "Community" },
    { label: "Family & education", value: "Family & education" },
    { label: "Fashion", value: "Fashion" },
    { label: "Hobbies", value: "Hobbies" },
    { label: "Home & lifestyle", value: "Home & lifestyle" },
    { label: "Performing & visual Arts", value: "Performing & visual Arts" },
    { label: "Government", value: "Government" },
    { label: "Spirituality", value: "Spirituality" },
    { label: "School Activities", value: "School Activities" },
    { label: "Science & tech", value: "Science & tech" },
    { label: "Holiday ", value: "Holiday" },
    { label: "Sports & fitness", value: "Sports & fitness" },
    { label: "Travel & outdoor ", value: "Travel & outdoor " },
    { label: "Other", value: "Other" },
  ];

  const customValidations = (name, value) => {
    let errorLength = 0;
    const args = [
      {
        name,
        value: value,
        validations: Validations.contractValidations[name],
      },
    ];

    ValidationService.validate(args, (validationErr, validationErrMsgs) => {
      if (validationErrMsgs.length > 0) {
        errorLength = errorLength + 1;
        errorMsg[name].errorMsg = validationErrMsgs[0].validations[0].message;
        setErrorMsg({ ...errorMsg });
      } else {
        errorMsg[name].errorMsg = "";
        setErrorMsg({ ...errorMsg });
      }
    });
    return errorLength;
  };

  const handleBrowse = (e) => {
    setImg(e.target.files[0]);
    var file = e.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      setImgSrc(reader.result);
    };
  };

  const dateConverter = (time) => {
    let date = new Date(time);
    // let convertedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    let convertedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${
      date.getDate() <= 9 ? "0" + date.getDate() : date.getDate()
    }`;

    return convertedDate;
  };

  const capitalizeFirstLetter = (value) => {
    return value && value[0].toUpperCase() + value.slice(1);
  };

  const handleChangeInput = (name, value) => {
    // console.log("namenvalue", name, value);
    if (name === "name") {
      setName(capitalizeFirstLetter(value));
    } else if (name === "description") {
      setDescription(value);
    } else if (name === "numberOfTickets") {
      setNumberOfTickets(value);
    } else if (name === "category") {
      setCategory(value);
    } else if (name === "date") {
      setDate(value);
    } else if (name === "venue") {
      setVenue(value);
    } else if (name === "venueDetails") {
      setVenueDetails(value);
    } else if (name === "ticketPrice") {
      setTicketPrice(value);
    } else {
      console.log("inputError");
    }
  };

  console.log("date", date);
  console.log("date", new Date(date).getTime());
  let test1 = new Date(date).getTime();
  console.log("test", new Date(test1));
  console.log("today", new Date());
  console.log("dateConverted", dateConverter(date));

  const createAndMint = async (e) => {
    e.preventDefault();

    if (!isWeb3Enabled) {
      // console.log("pls connect wallet");
      Swal.fire("", "Please connect your Metamask Wallet");
      return;
    }

    const chainId = await web3.eth.getChainId();

    if (chainId !== 97) {
      // console.log("pls change network");

      Swal.fire("", "Please switch your metamask network to BNB Smartchain Testnet Network");
      return;
    }

    let errorLengthName = customValidations("name", name);
    let errorLengthVenue = customValidations("venue", venue);
    let errorLengthNumberOfTickets = customValidations("numberOfTickets", numberOfTickets);
    let errorLengthTicketPrice = customValidations("ticketPrice", ticketPrice);
    let errorLengthDescription = customValidations("description", description);
    let errorLengthCategory = customValidations("category", category);

    if (
      errorLengthName !== 1 &&
      errorLengthVenue !== 1 &&
      // venue !== null &&
      errorLengthNumberOfTickets !== 1 &&
      errorLengthTicketPrice !== 1 &&
      errorLengthDescription !== 1 &&
      errorLengthCategory !== 1
    ) {
      console.log("no error");

      if (img === "") {
        Swal.fire({
          icon: "warning",
          title: "Please upload image",
        });
        return;
      }

      setLoader(true);

      let blockChain = "blockChain";
      let formData = new FormData();
      formData.append("name", name);
      // formData.append("eventType", eventType);
      formData.append("description", description);
      formData.append("price", ticketPrice);
      formData.append("category", category.value);
      formData.append("venue", venue.label);
      formData.append("photo", img);
      formData.append("blockChain", blockChain);

      const metaDataUri = await postMetaData(formData);

      console.log("metaDataUri", metaDataUri);

      const transaction = await mint(
        web3,
        address,
        numberOfTickets,
        ticketPrice.toString(),
        dateConverter(date),
        metaDataUri.metadataURI
      );

      console.log("transaction", transaction);
      setLoader(false);

      if (transaction.blockHash) {
        //apicall

        // console.log("tokenId", transaction.events.createTickets.returnValues.tokenId);
        console.log("eventDateRAW", date);
        console.log("eventDate", dateConverter(date));
        const weiTicketPrice = await web3.utils.toWei(ticketPrice.toString(), `ether`);
        const eventData = {
          creator: address,
          tokenId: transaction.events.createTickets.returnValues.tokenId,
          eventName: name,
          noOftickets: numberOfTickets,
          eventDate: dateConverter(date),
          price: weiTicketPrice,
          metaUri: metaDataUri.metadataURI,
          trasactionHashCreate: transaction.transactionHash,
          eventType: eventType,
          venue: venue.label,
          description: description,
          category: category.value,
        };

        const fetchCreateEventApiRes = await createEventApi(eventData);

        console.log("EventTypeRes", fetchCreateEventApiRes);

        Swal.fire({
          icon: "success",
          title: "Event created successfully",
          text: "It will be reflected in sometime",
        });
        setName("");
        setDescription("");
        setNumberOfTickets("");
        setDate(currentDate);
        setVenue("");
        setTicketPrice(1);
        setImgSrc("");
      } else {
        Swal.fire({
          icon: "error",
          title: "Create event failed",
          text: "Please try again later",
        });
      }
    } else {
      console.log("valid error");

      return;

      // if (venue === null) {
      //   Swal.fire({
      //     icon: "warning",
      //     title: "Please enter venue",
      //   });
      //   return;
      // }
    }
  };

  const handleFree = () => {
    setTicketType("Free");
    setTicketPrice(0);
  };

  const handleEventType = (name) => {
    if (name === "onlineEvent") {
      setEventType("Online");
      setVenue({ label: "Online", value: "Online" });
    } else {
      setEventType("Offline");
      setVenue("");
    }
  };

  return (
    <>
      <DashboardNavbar />
      <form autoComplete="off" onSubmit={(e) => createAndMint(e)}>
        <MDBox
          sx={{
            // marginBottom: "3rem",
            height: { xs: "none", md: "500px" },
            width: "100%",
            backgroundImage: `url(${imgSrc})`,
            objectFit: "contain",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Grid
            container
            sx={{
              height: "100%",

              backgroundImage:
                "linear-gradient(90deg,#1a1a1a,rgba(18, 18, 18, 0.9),rgba(18, 18, 18, 0.8),rgba(18, 18, 18, 0.9),rgba(18, 18, 18, 0.9), #1a1a1a)",
            }}
          >
            <Grid
              item
              container
              xs={12}
              md={4}
              sx={{
                height: "100%",
                marginBottom: { xs: "1rem", md: "none" },
                padding: "0 20px 0 0",
                justifyContent: "right",
                alignItems: "center",
              }}
            >
              {/* <MDBox sx={{ height: "100%", width: "100%", border: "1px solid red" }}> */}
              <MDBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <MDBox
                  sx={{
                    height: "400px",
                    width: "350px",
                    marginBottom: "0.5rem",
                    // border: "1px dashed #ffff",
                    outline: "1px dashed #ffff",
                    borderRadius: "10px 10px 10px 10px",

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  {imgSrc ? (
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px 10px 10px 10px",
                      }}
                      src={imgSrc}
                      alt="logo"
                    />
                  ) : (
                    <MDBox>
                      <MDTypography color="white">Image here</MDTypography>
                    </MDBox>
                  )}
                </MDBox>

                <MDBox sx={{}}>
                  <MDInput
                    type="file"
                    inputProps={{ accept: "image/*" }}
                    onChange={(e) => handleBrowse(e)}
                  />
                </MDBox>
              </MDBox>

              {/* </MDBox> */}
            </Grid>

            <Grid
              item
              container
              xs={12}
              md={4}
              sx={{
                paddingRight: { xs: "1rem", md: "none" },
                paddingLeft: { xs: "1rem", md: "none" },
                // alignItems: "center",

                // paddingRight: "20px",
                // paddingLeft: "20px",
                // paddingTop: "150px",
              }}
            >
              <MDBox
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <MDBox sx={{ marginBottom: "0.5rem" }}>
                  <MDInput
                    fullWidth
                    placeholder="Enter event name"
                    name="name"
                    value={name}
                    error={errorMsg.name.errorMsg.length > 0 || null}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <MDTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.name.errorMsg}
                  </MDTypography>
                </MDBox>
                <MDBox sx={{ marginBottom: "0.5rem" }}>
                  <MDBox sx={{ display: "flex", gap: "10px" }}>
                    <MDButton
                      name="offlineEvent"
                      variant={eventType === "Offline" ? "contained" : "outlined"}
                      size="small"
                      onClick={(e) => handleEventType(e.target.name)}
                    >
                      Offline
                    </MDButton>
                    <MDButton
                      name="onlineEvent"
                      variant={eventType === "Online" ? "contained" : "outlined"}
                      size="small"
                      onClick={(e) => handleEventType(e.target.name)}
                    >
                      Online
                    </MDButton>
                  </MDBox>
                </MDBox>
                {eventType === "Offline" ? (
                  <>
                    <MDBox sx={{ marginBottom: "0.5rem" }}>
                      <GooglePlacesAutocomplete
                        error={errorMsg.venue.errorMsg.length > 0 || null}
                        apiKey="AIzaSyDnRwrG8QHtzehsIYx-D3GuqjYRCT-qH7U"
                        selectProps={{
                          placeholder: "Select venue",
                          styles: {
                            placeholder: (provided) => ({
                              ...provided,
                              fontSize: "medium",
                            }),
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: "transparent",
                              borderWidth: "0.1px",
                              borderStyle: "ridge",
                              // borderTopStyle: "groove",
                            }),
                            menu: (provided) => ({
                              ...provided,
                              backgroundColor: "#121212",
                            }),
                            input: (provided, state) => ({
                              ...provided,
                              color: "white",
                            }),
                            option: (provided) => ({
                              ...provided,
                              color: "white",
                              cursor: "pointer",
                              "&:hover, &:focus, &:target, &:focus-within": {
                                backgroundColor: "#FF9607",
                              },
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              color: "white",
                            }),
                          },
                          // isClearable: true,
                          value: venue,
                          // onChange: setVenue,
                          onChange: (e) => {
                            handleChangeInput("venue", e);
                          },
                        }}
                      >
                        {/* {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div>
                        {suggestions.map((suggestion) => {
                          return <div>{suggestion.description}</div>;
                        })}
                      </div>
                    )} */}
                      </GooglePlacesAutocomplete>
                      <MDTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                        {errorMsg.venue.errorMsg}
                      </MDTypography>
                    </MDBox>
                    <MDBox sx={{ marginBottom: "0.5rem" }}>
                      <MDInput
                        fullWidth
                        placeholder="Venue details, Landmark, etc (optional)"
                        name="venueDetails"
                        value={venueDetails}
                        // error={errorMsg.numberOfTickets.errorMsg.length > 0}
                        onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                      />
                    </MDBox>
                  </>
                ) : null}

                <MDBox sx={{ marginBottom: "0.5rem" }}>
                  <MDInput
                    fullWidth
                    placeholder="Enter number of tickets"
                    type="number"
                    inputProps={{ min: "1" }}
                    name="numberOfTickets"
                    value={numberOfTickets}
                    error={errorMsg.numberOfTickets.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <MDTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.numberOfTickets.errorMsg}
                  </MDTypography>
                </MDBox>
                <MDBox sx={{ marginBottom: "0.5rem" }}>
                  <MDSelect
                    placeholder="Select category"
                    options={categoryList}
                    value={category}
                    error={errorMsg.category.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput("category", e)}
                  />
                  <MDTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.category.errorMsg}
                  </MDTypography>
                </MDBox>

                <MDBox sx={{ marginBottom: "0.5rem" }}>
                  {/* <MDTypography color="white" fontWeight="bold">
                    Event Date
                  </MDTypography> */}
                  {/* <MDInput name="date" value={date} onChange={() => handleChangeInput()} /> */}
                  <MDDatePicker
                    options={{ minDate: currentDate }}
                    value={date}
                    onChange={(e) => handleChangeInput("date", e[0])}
                  />
                </MDBox>
                <MDBox sx={{ marginBottom: "0.5rem" }}>
                  <MDBox sx={{ display: "flex", gap: "10px" }}>
                    <MDButton
                      variant={ticketType === "Free" ? "contained" : "outlined"}
                      size="small"
                      onClick={() => handleFree()}
                    >
                      Free
                    </MDButton>
                    <MDButton
                      variant={ticketType === "Paid" ? "contained" : "outlined"}
                      size="small"
                      onClick={() => setTicketType("Paid")}
                    >
                      Paid
                    </MDButton>
                  </MDBox>
                  {ticketType === "Paid" && (
                    <>
                      <MDInput
                        sx={{ marginTop: "10px" }}
                        fullWidth
                        type="number"
                        inputProps={{ min: "0", step: "any" }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img src={singhCoinLogo} width="18px" style={{}} />
                            </InputAdornment>
                          ),
                        }}
                        name="ticketPrice"
                        placeholder="Enter ticket price"
                        value={ticketPrice}
                        error={errorMsg.ticketPrice.errorMsg.length > 0}
                        onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                      />
                      <MDTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                        {errorMsg.ticketPrice.errorMsg}
                      </MDTypography>
                    </>
                  )}
                </MDBox>
              </MDBox>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={4}
              sx={{
                paddingRight: { xs: "1rem", md: "none" },
                paddingLeft: { xs: "1rem", md: "none" },
                alignItems: "center",
                justifyContent: "center",

                //   paddingRight: "20px",
                //   paddingLeft: "20px",
                paddingTop: { xs: "none", md: "80px" },
              }}
            >
              <MDBox sx={{ width: "100%", marginBottom: "3rem" }}>
                <MDInput
                  fullWidth
                  multiline={true}
                  rows={6}
                  name="description"
                  placeholder="Enter description..."
                  value={description}
                  error={errorMsg.description.errorMsg.length > 0}
                  onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                />
                <MDTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                  {errorMsg.description.errorMsg}
                </MDTypography>
              </MDBox>

              <MDBox sx={{ width: "50%" }}>
                <MDButton
                  variant="contained"
                  disabled={loader}
                  fullWidth
                  type="submit"
                  // onClick={() => createAndMint()}
                >
                  <MDTypography>
                    {loader ? <CircularProgress color="inherit" size={25} /> : "Mint"}
                  </MDTypography>
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </form>
      <Footer />
    </>
  );
}
