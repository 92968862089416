import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Dialog,
  Grid,
} from "@mui/material";
import demoTicket from "./demoTicket.jpg";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";

import { getIpfsData } from "services/contract";
import { getTokenList } from "services/contract";

import { useWallet } from "store/wallet";
import { useNavigate } from "react-router-dom";
// import { getMyEventsDetails } from "services/contract";
// import { getOtherEventsDetails } from "services/contract";
import { postMetaData } from "services/contract";
import { mint } from "services/contract";

import createPostIMG from "assets/images/CreatePost.png";

import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import MDSelect from "components/MDSelect";
import { getVenue } from "services/contract";
import profileUser from "./profileUser.png";
import MDAvatar from "components/MDAvatar";
import Loader from "loader/loader";

import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";

import { geocodeByAddress } from "react-google-places-autocomplete";
import Validations from "customValidations/validations";
import ValidationService from "customValidations/validationService";
import { getUpdatedPrice } from "services/contract";

// import EventCard from "./eventCard";
// import { getUserTokenBalance } from "services/contract";

let currentDate = new Date();
currentDate = `${currentDate.getFullYear()}-${
  currentDate.getMonth() + 1 <= 9 ? "0" + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1
}-${currentDate.getDate() <= 9 ? "0" + currentDate.getDate() : currentDate.getDate()}`;

export default function Events() {
  let navigate = useNavigate();
  const { web3, isWeb3Enabled, address } = useWallet();

  const [errorMsg, setErrorMsg] = useState({
    name: { errorMsg: "" },
    venue: { errorMsg: "" },
    description: { errorMsg: "" },
    numberOfTickets: { errorMsg: "" },
    ticketPrice: { errorMsg: "" },
    ticketPrice: { errorMsg: "" },
    date: { errorMsg: "" },
  });

  const [ticketType, setTicketType] = useState("Free");

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [numberOfTickets, setNumberOfTickets] = useState(1);
  const [date, setDate] = useState(currentDate);
  const [venue, setVenue] = useState("");
  const [ticketPrice, setTicketPrice] = useState(1);
  const [img, setImg] = useState("");
  const [imgSrc, setImgSrc] = useState("");

  const [createEventDialog, setCreateEventDialog] = useState(false);

  const [isViewAll, setViewAll] = useState(false);
  const [isViewAll2, setViewAll2] = useState(false);
  const [myEvents, setMyEvents] = useState([]);
  const [otherEvents, setOtherEvents] = useState([]);
  // const [ticketDetailsData, setTicketDetailsData] = useState([]);
  const [myEventsLoading, setMyEventsLoading] = useState(true);
  const [otherEventsLoading, setOtherEventsLoading] = useState(true);

  const [loader, setLoader] = useState(false);

  const customValidations = (name, value) => {
    let errorLength = 0;
    const args = [
      {
        name,
        value: value,
        validations: Validations.contractValidations[name],
      },
    ];

    ValidationService.validate(args, (validationErr, validationErrMsgs) => {
      if (validationErrMsgs.length > 0) {
        errorLength = errorLength + 1;
        errorMsg[name].errorMsg = validationErrMsgs[0].validations[0].message;
        setErrorMsg({ ...errorMsg });
      } else {
        errorMsg[name].errorMsg = "";
        setErrorMsg({ ...errorMsg });
      }
    });
    return errorLength;
  };

  const dateConverter = (time) => {
    let date = new Date(time);
    // let convertedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    let convertedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

    return convertedDate;
  };

  const handleChangeInput = (name, value) => {
    console.log("namenvalue", name, value);
    if (name === "name") {
      setName(value);
    } else if (name === "description") {
      setDescription(value);
    } else if (name === "numberOfTickets") {
      setNumberOfTickets(value);
    } else if (name === "date") {
      setDate(value);
    } else if (name === "venue") {
      setVenue(value);
    } else if (name === "ticketPrice") {
      setTicketPrice(value);
    } else {
      console.log("inputError");
    }
  };

  const handleBrowse = (e) => {
    setImg(e.target.files[0]);
    var file = e.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      setImgSrc(reader.result);
    };
  };

  const createAndMint = async (e) => {
    e.preventDefault();

    let errorLengthName = customValidations("name", name);
    let errorLengthVenue = customValidations("venue", venue);
    let errorLengthDescription = customValidations("description", description);

    if (errorLengthName === 1 && errorLengthVenue === 1 && errorLengthDescription === 1) {
      console.log("validerror");

      return;
    } else if (img === "") {
      setCreateEventDialog(false);
      // Swal.fire("", "Please upload image", "warning");
      Swal.fire({
        icon: "warning",
        title: "Please upload image",
        // text: "It will be reflected in sometime",
      }).then(() => {
        setCreateEventDialog(true);
      });
      return;
    }

    setLoader(true);

    let blockChain = "blockChain";
    let formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("price", ticketPrice);
    formData.append("venue", venue.label);
    formData.append("photo", img);
    formData.append("blockChain", blockChain);

    const metaDataUri = await postMetaData(formData);

    // console.log("metaDataUri", metaDataUri);

    // console.log("test");

    const transaction = await mint(
      web3,
      address,
      numberOfTickets,
      ticketPrice.toString(),
      dateConverter(date),
      metaDataUri.metadataURI
    );

    console.log("transaction", transaction);
    setLoader(false);

    setCreateEventDialog(false);

    // Swal.fire({
    //   icon: "success",
    //   text: "Event created successfully",
    // }).then(() => {
    //   // window.location.reload();
    //   setName("");
    //   setDescription("");
    //   setNumberOfTickets("");
    //   setDate("");
    //   setVenue("");
    //   setTicketPrice("");
    // });

    if (transaction.blockHash) {
      // setLoader(false);
      // setCreateEventDialog(false);
      Swal.fire({
        icon: "success",
        title: "Event created successfully",
        text: "It will be reflected in sometime",
      });
      // .then(() => {
      setName("");
      setDescription("");
      setNumberOfTickets(1);
      setDate(currentDate);
      setVenue("");
      setTicketPrice(1);
      setImgSrc("");
      // });
    } else {
      // setLoader(false);
      // setCreateEventDialog(false);
      Swal.fire({
        icon: "error",
        title: "Create event failed",
        text: "Please try again later",
      });
    }

    // Swal.fire("Event created successfully", "It will be reflected in sometime", "success");
    // setName("");
    // setDescription("");
    // setNumberOfTickets("");
    // setDate("");
    // setVenue("");
    // setTicketPrice("");
  };

  useEffect(() => {
    // fetchMyEventsDetails();
    // fetchOtherEventsDetails();
    eventsCallHandler();
  }, [address, loader]);

  // console.log("address", address);

  const eventsCallHandler = () => {
    if (address) {
      fetchMyEventsDetails();
      fetchOtherEventsDetails();
    } else console.log("connectMetamask");
  };

  const fetchMyEventsDetails = async () => {
    setMyEventsLoading(true);
    const myEvents = await getMyEventsDetails(address);
    for (let event of myEvents) {
      if (event.returnValues.metaUri) {
        const myEventsData = await getIpfsData(event.returnValues.metaUri);
        const updatedPrice = await getUpdatedPrice(web3, event.returnValues.tokenId);
        event.returnValues["name"] = myEventsData.name;
        event.returnValues["description"] = myEventsData.description;
        event.returnValues["photo"] = myEventsData.photo;
        event.returnValues["venue"] = myEventsData.venue;
        event.returnValues["blockChain"] = myEventsData.blockChain;
        event.returnValues["latestPrice"] = updatedPrice;
      }
    }
    setMyEvents(myEvents);
    setMyEventsLoading(false);
  };

  const fetchOtherEventsDetails = async () => {
    setOtherEventsLoading(true);
    const otherEvents = await getOtherEventsDetails();
    for (let event of otherEvents) {
      if (event.returnValues.metaUri) {
        const otherEventsData = await getIpfsData(event.returnValues.metaUri);
        const updatedPrice = await getUpdatedPrice(web3, event.returnValues.tokenId);
        event.returnValues["name"] = otherEventsData.name;
        event.returnValues["description"] = otherEventsData.description;
        event.returnValues["photo"] = otherEventsData.photo;
        event.returnValues["venue"] = otherEventsData.venue;
        event.returnValues["blockChain"] = otherEventsData.blockChain;
        event.returnValues["latestPrice"] = updatedPrice;
      }
    }
    setOtherEvents(otherEvents);
    setOtherEventsLoading(false);
  };

  const viewHandler = () => {
    setViewAll((prev) => !prev);
  };

  const viewHandler2 = () => {
    setViewAll2((prev) => !prev);
  };

  const handleMyEventViewDetails = (item) => {
    item["eventDetails"] = "myEvent";
    navigate("/details", { state: item });
  };

  const handleOtherEventViewDetails = (item) => {
    item["eventDetails"] = "otherEvent";
    navigate("/details", { state: item });
  };

  const handleCreateEventDialog = async () => {
    // const chainId = await web3.eth.getChainId();

    // console.log("chaindid", chainId);
    if (!isWeb3Enabled) {
      console.log("pls connect wallet");
      setCreateEventDialog(false);
      Swal.fire("", "Please connect your Metamask Wallet");
      return;
    }

    const chainId = await web3.eth.getChainId();

    console.log("chaindid", chainId);

    if (chainId !== 56) {
      console.log("pls change network");
      setCreateEventDialog(false);
      Swal.fire("", "Please connect your metamask network to BNB Smartchain Mainnet Network");
      return;
    }

    setCreateEventDialog(true);
  };

  const handleCloseCreatEventBox = () => {
    setCreateEventDialog(false);
  };

  const handleCloseCreateEvent = () => {
    setCreateEventDialog(false);
  };

  const handleRemoveImg = () => {
    setImgSrc("");
  };

  const handleFree = () => {
    setTicketType("Free");
    setTicketPrice(0);
  };

  // const [searchedVenue, setSearchedVenue] = useState([]);

  // const findVenue = (e) => {
  //   if (e != undefined || e != "") {
  //     if (e.length < 2) return;

  //     geocodeByAddress(e).then((response) => {
  //       const resp = response.map((data) => {
  //         data.label = `${data.formatted_address}`;
  //         data.value = data.formatted_address;
  //         return data;
  //       });
  //       console.log(resp, "resp");
  //       setSearchedVenue(resp);
  //       console.log("searchedVenue", searchedVenue);
  //     });
  //   }
  // };

  // const fixVenue = (e) => {
  //   console.log("e", e);
  //   setVenue(e);
  // };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox
        sx={{
          marginBottom: "50px",
          padding: "20px",
          borderRadius: "15px",
        }}
      >
        <MDBox
          sx={{
            width: "86%",
            display: "flex",
            justifyContent: "space-between",
            margin: "auto auto 50px auto",
          }}
        >
          <MDTypography fontWeight="bold" fontSize="caption" color="white">
            Your Events
          </MDTypography>

          <MDButton size="small" onClick={() => handleCreateEventDialog()}>
            <MDTypography fontSize="caption" color="text">
              Create Event
            </MDTypography>
          </MDButton>
        </MDBox>

        {isViewAll === true ? (
          <MDBox
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "25px",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            {/* <AuctionWidgets items={items} /> */}

            {myEvents &&
              myEvents.map((item, idx) => (
                <MDBox key={idx}>
                  {/* <EventCard /> */}

                  <Card sx={{ borderRadius: "15px", width: "250px" }}>
                    <CardMedia sx={{ margin: 0 }}>
                      <MDBox
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",

                          padding: "10px",
                          height: "250px",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "15px 15px 0px 0px",
                          backgroundSize: "cover",

                          backgroundImage: `url(${item.returnValues.photo})`,
                        }}
                      >
                        <MDBox
                          sx={{
                            marginLeft: "5px",
                            alignSelf: "start",
                            width: "90px",
                            backgroundColor: "#000000",
                            opacity: "60%",
                            borderRadius: "15px",
                            textAlign: "center",
                          }}
                        >
                          <MDTypography fontSize="small">
                            {item.returnValues.eventDate}
                          </MDTypography>
                        </MDBox>

                        {/* <MDBox
                          sx={{
                            width: "70px",
                            backgroundColor: "#000000",
                            opacity: "60%",
                            textAlign: "center",
                            borderRadius: "15px",
                            alignSelf: "end",
                          }}
                        >
                          <IconButton size="small">
                            <FavoriteBorderOutlinedIcon sx={{ color: "#ffffff" }} fontSize="5px" />
                            <MDTypography fontSize="15px">99</MDTypography>
                          </IconButton>
                        </MDBox> */}
                      </MDBox>
                    </CardMedia>
                    <CardContent sx={{ padding: 1 }}>
                      <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                        <MDBox
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Avatar src={profileUser} size="xs" />
                          <MDBox marginLeft="5px">
                            <MDTypography fontWeight="bold" fontSize="12px">
                              {item.returnValues.name}
                              {/* {props.ticketDetails.eventCreator} */}
                            </MDTypography>
                            {/* <MDTypography fontSize="10px">
                              by @{item.returnValues.name}
                            </MDTypography> */}
                          </MDBox>
                        </MDBox>
                        <MDBox
                          sx={{
                            marginTop: "5px",
                            textAlign: "center",
                          }}
                        >
                          <MDTypography color="white" fontWeight="bold" fontSize="small">
                            {item.returnValues.latestPrice === "0"
                              ? "Free"
                              : item.returnValues.latestPrice}
                          </MDTypography>

                          <MDTypography fontSize="10px">Ticket Price</MDTypography>
                        </MDBox>
                      </MDBox>
                    </CardContent>
                    <CardActions sx={{ display: "flex", justifyContent: "center" }}>
                      <MDButton
                        size="small"
                        color="light"
                        fullWidth
                        sx={{ borderRadius: "15px" }}
                        onClick={() => handleMyEventViewDetails(item)}
                      >
                        <MDTypography color="text">View Details</MDTypography>
                      </MDButton>
                    </CardActions>
                  </Card>
                </MDBox>
              ))}
          </MDBox>
        ) : (
          <MDBox
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "25px",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            {/* <AuctionSlider items={items} /> */}

            {myEvents && myEvents.length !== 0 ? (
              myEvents.slice(0, 4).map((item, idx) => (
                <MDBox key={idx}>
                  {/* <EventCard ticketDetails={item} /> */}

                  <Card sx={{ borderRadius: "15px", width: "250px" }}>
                    <CardMedia sx={{ margin: 0 }}>
                      <MDBox
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px",
                          height: "250px",
                          borderRadius: "15px 15px 0px 0px",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundImage: `url(${item.returnValues.photo})`,
                        }}
                      >
                        <MDBox
                          sx={{
                            marginLeft: "5px",
                            alignSelf: "start",
                            width: "90px",
                            backgroundColor: "#000000",
                            opacity: "60%",
                            borderRadius: "15px",
                            textAlign: "center",
                          }}
                        >
                          <MDTypography color="white" fontSize="small">
                            {item.returnValues.eventDate}
                          </MDTypography>
                        </MDBox>

                        {/* <MDBox
                            sx={{
                              width: "70px",
                              backgroundColor: "#000000",
                              opacity: "60%",
                              textAlign: "center",
                              borderRadius: "15px",
                              alignSelf: "end",
                            }}
                          >
                            <IconButton size="small">
                              <FavoriteBorderOutlinedIcon
                                sx={{ color: "#ffffff" }}
                                fontSize="5px"
                              />
                              <MDTypography fontSize="small">99</MDTypography>
                            </IconButton>
                          </MDBox> */}
                      </MDBox>
                    </CardMedia>
                    <CardContent sx={{ padding: 1 }}>
                      <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                        <MDBox
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {/* <Avatar src={profileUser} /> */}
                          <MDAvatar src={profileUser} size="xs" />
                          <MDBox marginLeft="5px">
                            <MDTypography fontWeight="bold" fontSize="12px">
                              {item.returnValues.name}
                              {/* {props.ticketDetails.eventCreator} */}
                            </MDTypography>
                            {/* <MDTypography fontSize="10px">
                              by @{item.returnValues.name}
                            </MDTypography> */}
                          </MDBox>
                        </MDBox>
                        <MDBox
                          sx={{
                            marginTop: "5px",
                            textAlign: "center",
                          }}
                        >
                          <MDTypography color="white" fontWeight="bold" fontSize="small">
                            {item.returnValues.price === "0"
                              ? "Free"
                              : item.returnValues.latestPrice}
                          </MDTypography>

                          <MDTypography fontSize="10px">Ticket Price</MDTypography>
                        </MDBox>
                      </MDBox>
                    </CardContent>
                    <CardActions sx={{ display: "flex", justifyContent: "center" }}>
                      <MDButton
                        size="small"
                        color="light"
                        fullWidth
                        sx={{ borderRadius: "15px" }}
                        onClick={() => handleMyEventViewDetails(item)}
                      >
                        <MDTypography color="text">View Details</MDTypography>
                      </MDButton>
                    </CardActions>
                  </Card>
                </MDBox>
              ))
            ) : (
              <MDBox>
                {myEventsLoading ? (
                  // <MDTypography>Fetching your events please wait...</MDTypography>
                  <Loader />
                ) : (
                  <MDTypography>There is no event, Create your event</MDTypography>
                )}
              </MDBox>
            )}
          </MDBox>
        )}
        <MDBox sx={{ textAlign: "center" }}>
          {myEvents.length > 4 && (
            <MDButton size="large" onClick={() => viewHandler()}>
              <MDTypography color="text">{isViewAll ? "Hide" : "View All"}</MDTypography>
            </MDButton>
          )}
        </MDBox>
      </MDBox>

      <MDBox
        sx={{
          marginBottom: "50px",
          padding: "20px",
          borderRadius: "15px",
        }}
      >
        <MDBox sx={{ marginBottom: "50px", marginLeft: "7%" }}>
          <MDTypography fontWeight="bold" fontSize="caption" color="white">
            Events
          </MDTypography>
        </MDBox>

        {isViewAll2 === true ? (
          <MDBox
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "25px",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            {/* <AuctionWidgets items={items} /> */}

            {otherEvents &&
              otherEvents.map((item, idx) => (
                <MDBox key={idx}>
                  {/* <EventCard /> */}
                  <Card sx={{ borderRadius: "15px", width: "250px" }}>
                    <CardMedia sx={{ margin: 0 }}>
                      <MDBox
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px",
                          height: "250px",
                          borderRadius: "15px 15px 0px 0px",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundImage: `url(${item.returnValues.photo})`,
                        }}
                      >
                        <MDBox
                          sx={{
                            marginLeft: "5px",
                            alignSelf: "start",
                            width: "90px",
                            backgroundColor: "#000000",
                            opacity: "60%",
                            borderRadius: "15px",
                            textAlign: "center",
                          }}
                        >
                          <MDTypography color="white" fontSize="small">
                            {item.returnValues.eventDate}
                          </MDTypography>
                        </MDBox>

                        {/* <MDBox
                            sx={{
                              width: "70px",
                              backgroundColor: "#000000",
                              opacity: "60%",
                              textAlign: "center",
                              borderRadius: "15px",
                              alignSelf: "end",
                            }}
                          >
                            <IconButton size="small">
                              <FavoriteBorderOutlinedIcon
                                sx={{ color: "#ffffff" }}
                                fontSize="5px"
                              />
                              <MDTypography fontSize="15px">99</MDTypography>
                            </IconButton>
                          </MDBox> */}
                      </MDBox>
                    </CardMedia>
                    <CardContent sx={{ padding: 1 }}>
                      <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                        <MDBox
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {/* <Avatar src={profileUser} /> */}
                          <MDAvatar src={profileUser} size="xs" />
                          <MDBox marginLeft="5px">
                            <MDTypography fontWeight="bold" fontSize="12px">
                              {item.returnValues.name}
                              {/* {props.ticketDetails.eventCreator} */}
                            </MDTypography>
                            {/* <MDTypography fontSize="10px">
                              by @{item.returnValues.name}
                            </MDTypography> */}
                          </MDBox>
                        </MDBox>
                        <MDBox
                          sx={{
                            marginTop: "5px",
                            textAlign: "center",
                          }}
                        >
                          <MDTypography color="white" fontWeight="bold" fontSize="small">
                            {item.returnValues.latestPrice === "0"
                              ? "Free"
                              : item.returnValues.latestPrice}
                          </MDTypography>

                          <MDTypography fontSize="10px">Ticket Price</MDTypography>
                        </MDBox>
                      </MDBox>
                    </CardContent>
                    <CardActions sx={{ display: "flex", justifyContent: "center" }}>
                      <MDButton
                        size="small"
                        color="light"
                        fullWidth
                        sx={{ borderRadius: "15px" }}
                        onClick={() => handleOtherEventViewDetails(item)}
                      >
                        <MDTypography color="text">View Details</MDTypography>
                      </MDButton>
                    </CardActions>
                  </Card>
                </MDBox>
              ))}
          </MDBox>
        ) : (
          <MDBox>
            <MDBox
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "25px",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              {/* <AuctionSlider items={items} /> */}

              {otherEvents && otherEvents.length !== 0 ? (
                otherEvents.slice(0, 4).map((item, idx) => (
                  <MDBox key={idx}>
                    {/* <EventCard /> */}
                    <Card sx={{ borderRadius: "15px", width: "250px" }}>
                      <CardMedia sx={{ margin: 0 }}>
                        <MDBox
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "10px",
                            height: "250px",
                            borderRadius: "15px 15px 0px 0px",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundImage: `url(${item.returnValues.photo})`,
                          }}
                        >
                          <MDBox
                            sx={{
                              marginLeft: "5px",
                              alignSelf: "start",
                              width: "90px",
                              backgroundColor: "#000000",
                              opacity: "60%",
                              borderRadius: "15px",
                              textAlign: "center",
                            }}
                          >
                            <MDTypography color="white" fontSize="small">
                              {item.returnValues.eventDate}
                            </MDTypography>
                          </MDBox>

                          {/* <MDBox
                            sx={{
                              width: "70px",
                              backgroundColor: "#000000",
                              opacity: "60%",
                              textAlign: "center",
                              borderRadius: "15px",
                              alignSelf: "end",
                            }}
                          >
                            <IconButton size="small">
                              <FavoriteBorderOutlinedIcon
                                sx={{ color: "#ffffff" }}
                                fontSize="5px"
                              />
                              <MDTypography fontSize="15px">99</MDTypography>
                            </IconButton>
                          </MDBox> */}
                        </MDBox>
                      </CardMedia>
                      <CardContent sx={{ padding: 1 }}>
                        <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                          <MDBox
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {/* <Avatar src={profileUser} /> */}
                            <MDAvatar src={profileUser} size="xs" />
                            <MDBox marginLeft="5px">
                              <MDTypography fontWeight="bold" fontSize="12px">
                                {item.returnValues.name}
                                {/* {props.ticketDetails.eventCreator} */}
                              </MDTypography>
                              {/* <MDTypography fontSize="10px">
                                by @{item.returnValues.name}
                              </MDTypography> */}
                            </MDBox>
                          </MDBox>
                          <MDBox
                            sx={{
                              marginTop: "5px",
                              textAlign: "center",
                            }}
                          >
                            <MDTypography color="white" fontWeight="bold" fontSize="small">
                              {item.returnValues.price === "0"
                                ? "Free"
                                : item.returnValues.latestPrice}
                            </MDTypography>

                            <MDTypography fontSize="10px">Ticket Price</MDTypography>
                          </MDBox>
                        </MDBox>
                      </CardContent>
                      <CardActions sx={{ display: "flex", justifyContent: "center" }}>
                        <MDButton
                          size="small"
                          color="light"
                          fullWidth
                          sx={{ borderRadius: "15px" }}
                          onClick={() => handleOtherEventViewDetails(item)}
                        >
                          <MDTypography color="text">View Details</MDTypography>
                        </MDButton>
                      </CardActions>
                    </Card>
                  </MDBox>
                ))
              ) : (
                <MDBox>
                  {otherEventsLoading ? (
                    // <MDTypography>Fetching events please wait...</MDTypography>
                    <Loader />
                  ) : (
                    <MDTypography>No Events</MDTypography>
                  )}
                </MDBox>
              )}
            </MDBox>
          </MDBox>
        )}
        <MDBox sx={{ textAlign: "center" }}>
          {otherEvents.length > 4 && (
            <MDButton size="large" onClick={() => viewHandler2()}>
              <MDTypography color="text">{isViewAll2 ? "Hide" : "View All"}</MDTypography>
            </MDButton>
          )}
        </MDBox>
      </MDBox>

      <Dialog
        open={createEventDialog}
        onClose={() => handleCloseCreatEventBox()}
        // onClose={(_, reason) => {
        //   if (reason !== "backdropClick") {
        //     handleCloseCreatEventBox();
        //   }
        // }}
        fullWidth
        maxWidth="lg"
        disableEscapeKeyDown={true}
        sx={{
          "& .MuiDialog-paper::-webkit-scrollbar": {
            display: "none",
          },
          "& .MuiDialog-paper": {
            borderRadius: "25px",

            // outline: "2px solid #ffff",
          },
          marginLeft: "3%",
        }}
      >
        <MDBox
          sx={{
            // border: "1px solid red",
            // borderRadius: "25px",
            padding: "5px",
            backgroundImage: `url(${createPostIMG})`,
            backgroundSize: "cover",
          }}
        >
          <MDBox>
            <MDTypography color="button" fontWeight="bold" fontSize="2rem" marginLeft="2%">
              Create Event
            </MDTypography>
          </MDBox>
          <form onSubmit={(e) => createAndMint(e)}>
            <Grid container>
              {/* <MDBox sx={{ display: "flex", justifyContent: "space-between", padding: "5px" }}> */}
              {/* <MDBox
              sx={{
                width: "50%",
              }}
            > */}
              <Grid item xs={12} md={6} sx={{ padding: "10px 10px 0px 10px" }}>
                <MDBox sx={{ height: "70%", marginTop: "10px" }}>
                  <Card
                    sx={{
                      height: "100%",
                      outline: "1px dashed #ffff",
                      background: "transparent",
                      padding: "0px 0px 10px 0px",
                    }}
                  >
                    <CardContent
                      sx={{
                        height: "80%",
                        padding: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      {imgSrc ? (
                        <MDBox
                          sx={{
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "10px 10px 0px 0px",
                            }}
                            src={imgSrc}
                            alt="logo"
                          />
                        </MDBox>
                      ) : (
                        <MDBox>
                          <MDTypography color="white">Image here</MDTypography>
                          <MDInput
                            type="file"
                            inputProps={{ accept: "image/*" }}
                            onChange={(e) => handleBrowse(e)}
                          />
                        </MDBox>
                      )}
                    </CardContent>
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <MDBox
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {/* <Avatar src={profileUser} /> */}
                        <MDAvatar src={profileUser} size="sm" />
                        <MDBox marginLeft="3px">
                          <MDTypography color="white" fontWeight="bold" fontSize="12px">
                            Event name
                          </MDTypography>
                          <MDTypography color="white" fontSize="10px">
                            by @Creator name
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox
                        sx={{
                          marginTop: "5px",
                          textAlign: "center",
                        }}
                      >
                        <MDBox
                        // size="small"
                        >
                          <MDTypography color="white" fontWeight="bold" fontSize="15px">
                            100
                          </MDTypography>
                        </MDBox>
                        <MDTypography color="white" fontSize="10px">
                          Ticket Price
                        </MDTypography>
                      </MDBox>
                    </CardActions>
                  </Card>
                </MDBox>
                <MDBox
                  sx={{
                    height: "29%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MDButton size="small" disabled={!imgSrc} onClick={() => handleRemoveImg()}>
                    <MDTypography>Remove Image</MDTypography>
                  </MDButton>
                </MDBox>
                {/* </MDBox> */}
              </Grid>

              <Grid item xs={12} md={6} sx={{ padding: "10px 10px 0 10px" }}>
                <MDBox sx={{}}>
                  <MDTypography color="white" fontWeight="bold">
                    Event Name
                  </MDTypography>
                  <MDInput
                    fullWidth
                    name="name"
                    placeholder="Enter event name"
                    value={name}
                    error={errorMsg.name.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <MDTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.name.errorMsg}
                  </MDTypography>
                </MDBox>

                <MDBox sx={{}}>
                  <MDTypography color="white" fontWeight="bold">
                    Event Venue
                  </MDTypography>

                  <GooglePlacesAutocomplete
                    error={errorMsg.venue.errorMsg.length > 0}
                    apiKey="AIzaSyDnRwrG8QHtzehsIYx-D3GuqjYRCT-qH7U"
                    selectProps={{
                      styles: {
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "transparent",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          backgroundImage: `url(${createPostIMG})`,
                        }),
                        input: (provided) => ({
                          ...provided,
                          color: "white",
                        }),
                        option: (provided) => ({
                          ...provided,
                          color: "white",
                          cursor: "pointer",
                          "&:hover, &:focus, &:target, &:focus-within": {
                            backgroundColor: "#FF9607",
                            // color: state.isDisabled ? dark.main : white.main,

                            // "&:after": {
                            //   content: state.isDisabled && "''",
                            //   opacity: 0.5,
                            // },
                          },
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: "white",
                        }),
                      },

                      isClearable: true,
                      value: venue,

                      // onChange: setVenue,
                      onChange: (e) => {
                        handleChangeInput("venue", e);
                      },
                    }}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div>
                        {suggestions.map((suggestion) => {
                          return <div>{suggestion.description}</div>;
                        })}
                      </div>
                    )}
                  </GooglePlacesAutocomplete>
                  <MDTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.venue.errorMsg}
                  </MDTypography>
                  {/* <MDInput
                  fullWidth
                  name="venue"
                  placeholder="Enter venue"
                  value={venue}
                  onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                /> */}
                  {/* <MDSelect
                    placeholder="Select"
                    options={[
                      { value: "test", label: "test2" },
                      { value: "test", label: "test2" },
                    ]}
                    value={venue}
                    onInputChange={(e) => findVenue(e)}
                    onChange={(e) => fixVenue(e)}
                  /> */}
                </MDBox>

                <MDBox sx={{}}>
                  <MDTypography color="white" fontWeight="bold">
                    Event Description
                  </MDTypography>
                  <MDInput
                    fullWidth
                    multiline={true}
                    rows={4}
                    name="description"
                    placeholder="Enter description"
                    value={description}
                    error={errorMsg.description.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <MDTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.description.errorMsg}
                  </MDTypography>
                </MDBox>

                <MDBox sx={{}}>
                  <MDTypography color="white" fontWeight="bold">
                    Number of Tickets
                  </MDTypography>
                  <MDInput
                    type="number"
                    inputProps={{ min: "1" }}
                    fullWidth
                    name="numberOfTickets"
                    placeholder="Enter no. of tickets"
                    value={numberOfTickets}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                </MDBox>

                {/* <MDBox sx={{ marginBottom: "3rem" }}>
                <MDInput type="file" onChange={upload} />
              </MDBox> */}
                <MDBox sx={{}}>
                  <MDTypography color="white" fontWeight="bold">
                    Set Ticket Price
                  </MDTypography>

                  <MDBox sx={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
                    <MDButton
                      variant={ticketType === "Free" ? "contained" : "outlined"}
                      size="small"
                      onClick={() => handleFree()}
                    >
                      Free
                    </MDButton>
                    <MDButton
                      variant={ticketType === "Paid" ? "contained" : "outlined"}
                      size="small"
                      onClick={() => setTicketType("Paid")}
                    >
                      Paid
                    </MDButton>
                  </MDBox>
                  {ticketType === "Paid" && (
                    <MDInput
                      fullWidth
                      type="number"
                      inputProps={{ min: "1" }}
                      name="ticketPrice"
                      placeholder="Enter ticketprice"
                      value={ticketPrice}
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                  )}
                </MDBox>
                <MDBox sx={{ marginBottom: "1rem" }}>
                  <MDTypography color="white" fontWeight="bold">
                    Event Date
                  </MDTypography>
                  {/* <MDInput name="date" value={date} onChange={() => handleChangeInput()} /> */}
                  <MDDatePicker
                    options={{ altFormat: "Y/m/d", minDate: currentDate }}
                    value={date}
                    onChange={(e) => handleChangeInput("date", e[0])}
                  />
                </MDBox>
                <MDBox sx={{}}>
                  <MDButton
                    variant="contained"
                    disabled={loader}
                    fullWidth
                    type="submit"
                    // onClick={() => createAndMint()}
                  >
                    <MDTypography>
                      {loader ? <CircularProgress color="inherit" size={25} /> : "Mint"}
                    </MDTypography>
                  </MDButton>

                  {/* <MDInput /> */}
                </MDBox>
              </Grid>
              {/* </MDBox> */}
            </Grid>
          </form>
        </MDBox>
      </Dialog>
    </DashboardLayout>
  );
}
