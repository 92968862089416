import React from "react";
import { useMediaQuery } from "react-responsive";
import Skeleton from "@mui/material/Skeleton";
import MDBox from "components/MDBox";
import { Divider, Grid } from "@mui/material";

export default function DetailedLoader() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <>
      <MDBox
        sx={{
          marginBottom: "3rem",
          height: { xs: "none", md: "500px" },
          width: "100%",
          //   backgroundImage: `url(${photo})`,
          //   objectFit: "contain",
          //   backgroundRepeat: "no-repeat",
          //   backgroundSize: "cover",
          //   backgroundPosition: "center",
        }}
      >
        <Grid
          container
          sx={{
            height: "100%",
          }}
        >
          <Grid
            item
            container
            xs={12}
            md={4}
            sx={{
              height: "100%",
              // marginBottom: { xs: "1rem", md: "none" },
              padding: "0 20px 0 0",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <MDBox sx={{ height: "400px", width: "350px" }}>
              {/* <img
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px 10px 10px 10px",
                }}
                // src={photo}
                alt="logo"
              /> */}
              <Skeleton
                sx={{
                  bgcolor: "grey.900",
                  borderRadius: "10px",
                  width: "100%",
                  height: "100%",
                }}
                variant="rounded"
              />
            </MDBox>
          </Grid>

          <Grid
            item
            container
            xs={12}
            md={5}
            sx={{
              paddingLeft: "1rem",
              // paddingRight: { xs: "1rem", md: "none" },
              // paddingLeft: { xs: "1rem", md: "none" },
              alignItems: "center",
            }}
          >
            <MDBox sx={{}}>
              <MDBox sx={{ width: "100%", marginBottom: "2rem" }}>
                {/* details */}
                <Skeleton
                  sx={{
                    bgcolor: "grey.900",
                    // borderRadius: "15px",
                    fontSize: "20px",
                    // width: { xs: 350, sm: 350, md: 280 },
                    width: 500,
                    // display: { xs: "none", sm: "block", md: "block", lg: "block" },
                  }}
                  variant="text"
                  //   height={400}
                />
                <Skeleton
                  sx={{
                    bgcolor: "grey.900",
                    // borderRadius: "15px",
                    fontSize: "40px",
                    // width: { xs: 350, sm: 350, md: 280 },
                    width: 500,
                    // display: { xs: "none", sm: "block", md: "block", lg: "block" },
                  }}
                  variant="text"
                  //   height={400}
                />
                <Skeleton
                  sx={{
                    bgcolor: "grey.900",
                    // borderRadius: "15px",
                    fontSize: "20px",
                    // width: { xs: 350, sm: 350, md: 280 },
                    width: 400,
                    // display: { xs: "none", sm: "block", md: "block", lg: "block" },
                  }}
                  variant="text"
                  //   height={400}
                />
                <Skeleton
                  sx={{
                    bgcolor: "grey.900",
                    // borderRadius: "15px",
                    fontSize: "20px",
                    // width: { xs: 350, sm: 350, md: 280 },
                    width: 300,
                    // display: { xs: "none", sm: "block", md: "block", lg: "block" },
                  }}
                  variant="text"
                  //   height={400}
                />
                <Skeleton
                  sx={{
                    bgcolor: "grey.900",
                    // borderRadius: "15px",
                    fontSize: "20px",
                    // width: { xs: 350, sm: 350, md: 280 },
                    width: 200,
                    // display: { xs: "none", sm: "block", md: "block", lg: "block" },
                  }}
                  variant="text"
                  //   height={400}
                />
              </MDBox>
              {/* <MDBox
                sx={{
                  border: "1px solid yellow",
                  marginBottom: "2rem",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
               price
              </MDBox> */}
              <MDBox sx={{ width: "100%" }}>
                {/* button */}
                <Skeleton
                  sx={{
                    bgcolor: "grey.900",
                    borderRadius: "10px",

                    // width: { xs: 350, sm: 350, md: 280 },
                    width: 150,
                    height: 40,
                    // display: { xs: "none", sm: "block", md: "block", lg: "block" },
                  }}
                  variant="rounded"
                  //   height={400}
                />
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <Grid
        container
        sx={{
          marginBottom: "2rem",
          // border: "1px solid red",
          // paddingLeft: "9rem",
          // paddingRight: "9rem",
          // marginBottom: "2rem",
          paddingLeft: { xs: "1rem", md: "8rem" },
          paddingRight: { xs: "1rem", md: "8rem" },
        }}
      >
        <Grid item container xs={12} sx={{ flexDirection: "column" }}>
          <Grid item>
            <MDBox sx={{}}>
              <Skeleton
                sx={{
                  bgcolor: "grey.900",
                  // borderRadius: "15px",
                  fontSize: "30px",
                  // width: { xs: 350, sm: 350, md: 280 },
                  width: 300,
                  // display: { xs: "none", sm: "block", md: "block", lg: "block" },
                }}
                variant="text"
                //   height={400}
              />
              <Skeleton
                sx={{
                  bgcolor: "grey.900",
                  // borderRadius: "15px",
                  fontSize: "20px",
                  // width: { xs: 350, sm: 350, md: 280 },
                  width: 800,
                  // display: { xs: "none", sm: "block", md: "block", lg: "block" },
                }}
                variant="text"
                //   height={400}
              />
              <Skeleton
                sx={{
                  bgcolor: "grey.900",
                  // borderRadius: "15px",
                  fontSize: "20px",
                  // width: { xs: 350, sm: 350, md: 280 },
                  width: 800,
                  // display: { xs: "none", sm: "block", md: "block", lg: "block" },
                }}
                variant="text"
                //   height={400}
              />
              {/* <MDTypography variant="h3">About the event</MDTypography> */}
            </MDBox>
          </Grid>
        </Grid>
      </Grid>

      <Divider />

      <Grid
        sx={{
          marginBottom: "5rem",
          // border: "1px solid red",
          // paddingLeft: "9rem",
          // paddingRight: "9rem",
          // marginBottom: "2rem",
          paddingLeft: { xs: "1rem", md: "8rem" },
          paddingRight: { xs: "1rem", md: "8rem" },
        }}
      >
        <MDBox
          sx={{
            // display: "flex",
            // justifyContent: "space-between",
            marginBottom: "0.5rem",
          }}
        >
          <Skeleton
            sx={{
              bgcolor: "grey.900",
              // borderRadius: "15px",
              fontSize: "30px",
              // width: { xs: 350, sm: 350, md: 280 },
              width: 300,
              // display: { xs: "none", sm: "block", md: "block", lg: "block" },
            }}
            variant="text"
            //   height={400}
          />
          <Skeleton
            sx={{
              bgcolor: "grey.900",
              // borderRadius: "15px",
              fontSize: "20px",
              // width: { xs: 350, sm: 350, md: 280 },
              width: 400,
              // display: { xs: "none", sm: "block", md: "block", lg: "block" },
            }}
            variant="text"
            //   height={400}
          />
          <Skeleton
            sx={{
              bgcolor: "grey.900",
              // borderRadius: "15px",
              fontSize: "20px",
              // width: { xs: 350, sm: 350, md: 280 },
              width: 400,
              // display: { xs: "none", sm: "block", md: "block", lg: "block" },
            }}
            variant="text"
            //   height={400}
          />
          <Skeleton
            sx={{
              bgcolor: "grey.900",
              // borderRadius: "15px",
              fontSize: "20px",
              // width: { xs: 350, sm: 350, md: 280 },
              width: 350,
              // display: { xs: "none", sm: "block", md: "block", lg: "block" },
            }}
            variant="text"
            //   height={400}
          />
          <Skeleton
            sx={{
              bgcolor: "grey.900",
              // borderRadius: "15px",
              fontSize: "20px",
              // width: { xs: 350, sm: 350, md: 280 },
              width: 300,
              // display: { xs: "none", sm: "block", md: "block", lg: "block" },
            }}
            variant="text"
            //   height={400}
          />
          <Skeleton
            sx={{
              bgcolor: "grey.900",
              // borderRadius: "15px",
              fontSize: "20px",
              // width: { xs: 350, sm: 350, md: 280 },
              width: 250,
              // display: { xs: "none", sm: "block", md: "block", lg: "block" },
            }}
            variant="text"
            //   height={400}
          />
          <Skeleton
            sx={{
              bgcolor: "grey.900",
              // borderRadius: "15px",
              fontSize: "20px",
              // width: { xs: 350, sm: 350, md: 280 },
              width: 200,
              // display: { xs: "none", sm: "block", md: "block", lg: "block" },
            }}
            variant="text"
            //   height={400}
          />
        </MDBox>
      </Grid>
    </>
  );
}
