import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";
import { useMediaQuery } from "react-responsive";
import Banner from "./banner";
import MDTypography from "components/MDTypography";
import { Avatar, Card, CardContent, CardMedia } from "@mui/material";
// import carouselImages from "./carouselImage.jpg";
import "./carousel.css";
import profileUser from "./profileUser.png";
import { getMyEventsDetails } from "services/contract";
import { getOtherEventsDetails } from "services/contract";
import { getIpfsData } from "services/contract";
import { getUpdatedPrice } from "services/contract";
import { useWallet } from "store/wallet";
import add1 from "./add.png";
import add1mb from "./addmb.png";
import SkeletonLoader from "skeletonLoader";
import SinghCoin from "./SinghCoin.png";
import { useNavigate } from "react-router-dom";
import MDAvatar from "components/MDAvatar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import OngoingEvents from "./ongoingEvents";
import UpcomingEvents from "./upcomingEvents";
import PastEvents from "./pastEvents";

export default function SinghDashboard() {
  // let navigate = useNavigate();
  // const { web3, isWeb3Enabled, address } = useWallet();
  // const [loader, setLoader] = useState(false);
  // const [myEvents, setMyEvents] = useState([]);
  // const [myEventsLoading, setMyEventsLoading] = useState(true);

  // const [otherEvents, setOtherEvents] = useState([]);
  // const [otherEventsLoading, setOtherEventsLoading] = useState(true);

  // const [recentEvents, setRecentEvents] = useState([]);
  // const [ongoingEvents, setOngoingEvents] = useState([]);
  // const [upcomingEvents, setUpcomingEvents] = useState([]);
  // const [pastEvents, setPastEvents] = useState([]);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  // const responsive = {
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 4,
  //     slidesToSlide: 4,
  //   },
  //   laptop: {
  //     breakpoint: { max: 1024, min: 768 },
  //     items: 3,
  //     slidesToSlide: 2,
  //   },
  //   tablet: {
  //     breakpoint: { max: 768, min: 464 },
  //     items: 2,
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 330 },
  //     items: 1,
  //   },
  //   mobileSmall: {
  //     breakpoint: { max: 320, min: 0 },
  //     items: 1,
  //     slidesToSlide: 1,
  //   },
  // };

  // useEffect(() => {
  //   eventsCallHandler();
  // }, [address, loader]);

  // const eventsCallHandler = () => {
  //   if (address) {
  //     // fetchMyEventsDetails();
  //     fetchOtherEventsDetails();
  //   } else console.log("connectMetamask");
  // };

  // const fetchMyEventsDetails = async () => {
  //   setMyEventsLoading(true);
  //   const myEvents = await getMyEventsDetails(address);
  //   for (let event of myEvents) {
  //     if (event.returnValues.metaUri) {
  //       const myEventsData = await getIpfsData(event.returnValues.metaUri);
  //       const updatedPrice = await getUpdatedPrice(web3, event.returnValues.tokenId);
  //       event.returnValues["name"] = myEventsData.name;
  //       event.returnValues["description"] = myEventsData.description;
  //       event.returnValues["photo"] = myEventsData.photo;
  //       event.returnValues["venue"] = myEventsData.venue;
  //       event.returnValues["blockChain"] = myEventsData.blockChain;
  //       event.returnValues["latestPrice"] = updatedPrice;
  //     }
  //   }
  //   setMyEvents(myEvents);
  //   setMyEventsLoading(false);
  // };

  // const fetchOtherEventsDetails = async () => {
  //   setOtherEventsLoading(true);
  //   const otherEvents = await getOtherEventsDetails();
  //   for (let event of otherEvents) {
  //     if (event.returnValues.metaUri) {
  //       const otherEventsData = await getIpfsData(event.returnValues.metaUri);
  //       const updatedPrice = await getUpdatedPrice(web3, event.returnValues.tokenId);
  //       event.returnValues["name"] = otherEventsData.name;
  //       event.returnValues["description"] = otherEventsData.description;
  //       event.returnValues["photo"] = otherEventsData.photo;
  //       event.returnValues["venue"] = otherEventsData.venue;
  //       event.returnValues["blockChain"] = otherEventsData.blockChain;
  //       event.returnValues["latestPrice"] = updatedPrice;
  //     }
  //   }
  //   setOtherEvents(otherEvents);

  //   const ongoingEventsFunc = (item) => {
  //     if (new Date(item.returnValues.eventDate).toDateString() === new Date().toDateString()) {
  //       return item;
  //     }
  //   };

  //   setOngoingEvents(otherEvents.filter(ongoingEventsFunc));

  //   const upcomingEventsFunc = (item) => {
  //     if (new Date(item.returnValues.eventDate) > new Date()) {
  //       return item;
  //     }
  //   };

  //   setUpcomingEvents(otherEvents.filter(upcomingEventsFunc));

  //   const pastEventsFunc = (item) => {
  //     if (new Date(item.returnValues.eventDate) < new Date()) {
  //       return item;
  //     }
  //   };
  //   setPastEvents(otherEvents.filter(pastEventsFunc));

  //   setOtherEventsLoading(false);
  // };

  // console.log("myE", myEvents);
  // console.log("oE", otherEvents);
  // console.log("UP", upcomingEvents);
  // console.log("ON", ongoingEvents);
  // console.log("Past", pastEvents);

  // const handleEventDetails = (item) => {
  //   item["eventDetails"] = "otherEvent";
  //   navigate("/detailsnew", { state: item });
  // };

  // const handleSeeAllEvents = (name) => {
  //   console.log("name", name);
  //   // item["eventDetails"] = "otherEvent";
  //   navigate("/explore", { state: name });
  // };

  return (
    <>
      <DashboardNavbar />
      <Banner />
      <OngoingEvents />
      <MDBox
        className="addV1"
        sx={{
          height: "160px",
          marginRight: isTabletOrMobile ? "none" : "10%",
          marginLeft: isTabletOrMobile ? "none" : "10%",
          marginBottom: "5rem",
        }}
      >
        <img src={isTabletOrMobile ? add1mb : add1} width="100%" height="100%" alt="add" />
      </MDBox>
      <UpcomingEvents />
      <PastEvents />
      <Footer />
    </>
  );
}
