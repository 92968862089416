module.exports = {
  eventContractAddress: "0x945889AFf61f3C759809B41027d06b7680576021", //testnet

  // eventContractAddress: "0xC992264502bf1304ac8c5Fdb9863A5d2F7230FB4", //mainnet

  singhCoinAddress: "0xEF4Bd5C378B6fBa34B2549dAF2E0997eB9EC2dDb", // testnet

  // singhCoinAddress: "0x867B96B33B2c13CC8cB78A9aA95420c6cD42C4c6", // mainnet

  // ticketPlatformAddress: "0x345843bEFA1a5E0bbE679f2C704cC6067d3e47bC",
  // chainId: 56, //mainnet
  // chainId: 97, //testnet
  // usdtAddress: "0x55d398326f99059fF775485246999027B3197955",
  // bnbAddress: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
};
