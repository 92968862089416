import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useMediaQuery } from "react-responsive";

import MDTypography from "components/MDTypography";
import { Avatar, Card, CardContent, CardMedia, IconButton, InputAdornment } from "@mui/material";

// import { getOtherEventsDetails } from "services/contract";
import { getIpfsData } from "services/contract";
import { getUpdatedPrice } from "services/contract";
import { useWallet } from "store/wallet";
import SkeletonLoader from "skeletonLoader";
import SinghCoin from "./SinghCoin.png";
import { useNavigate } from "react-router-dom";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getAllEventList } from "services/contract";
import MDInput from "components/MDInput";
import SearchIcon from "@mui/icons-material/Search";
import { getSearch } from "services/contract";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";
import Loader from "loader/loader";
import { MdOutlineCategory } from "react-icons/md";
import { TbCalendarStats } from "react-icons/tb";

export default function Search() {
  let navigate = useNavigate();
  const { web3, isWeb3Enabled, address } = useWallet();
  const [otherEvents, setOtherEvents] = useState([]);
  const [otherEventsLoading, setOtherEventsLoading] = useState(true);
  const [ongoingEvents, setOngoingEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  // let pageNumber = 0;

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4,
    },
    laptop: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 330 },
      items: 1,
    },
    mobileSmall: {
      breakpoint: { max: 320, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const handleSearch = async (value) => {
    setSearchTerm(value);

    if (value.length <= 0 || value === "") {
      console.log("empty");
      setSearchLoading(false);
      setSearchResult([]);
      return;
    }

    console.log("value", value);
    const searchRes = await getSearch(value, 0);
    console.log("searchRes", searchRes);
    setSearchResult(searchRes);
    setSearchLoading(true);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleEventDetails = (name, item) => {
    navigate(`/event-details/?eventDetails=${name}&tokenId=${item.tokenId}`);
  };

  return (
    <>
      <MDBox sx={{ height: "100%" }}>
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "space-around",
            // marginRight: isTabletOrMobile ? "5%" : "10%",
            // marginLeft: isTabletOrMobile ? "5%" : "10%",
            // marginTop: "2rem",
            // marginBottom: "2rem",
            alignItems: "center",
            height: "100px",
            backgroundColor: "#1a1a1a",
          }}
        >
          {/* {isDesktopOrLaptop ? ( */}
          <IconButton onClick={() => handleBack()}>
            <MDTypography color="button" fontSize="30px">
              <ArrowBackIcon />
            </MDTypography>
          </IconButton>
          <MDBox sx={{ width: { xs: "80%", md: "40%" } }}>
            {/* <form onSubmit={submitData}> */}
            <MDInput
              autoComplete="off"
              fullWidth
              sx={
                {
                  //   margin: "auto",
                  // width: "200%",
                }
              }
              placeholder="Search events..."
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon sx={{ color: "#fff" }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* </form> */}
          </MDBox>
          {/* ) : (
            <IconButton>
              <SearchIcon sx={{ color: "#FF9607" }} />
            </IconButton>
          )} */}
          <IconButton onClick={() => handleBack()}>
            <MDTypography color="button" fontSize="30px">
              <ClearIcon />
            </MDTypography>
          </IconButton>
        </MDBox>

        <MDBox
          sx={{
            position: "absolute",
            width: "100%",
            top: 80,

            display: "flex",
            // gap: "2.5rem",
            justifyContent: "center",
            // flexWrap: "wrap",

            // marginRight: isTabletOrMobile ? "5%" : "10%",
            // marginLeft: isTabletOrMobile ? "5%" : "10%",
            marginBottom: "2rem",
            //   marginBottom: "2rem",
            //   alignItems: "center",
          }}
        >
          <MDBox sx={{ width: { xs: "75%", md: "40%" } }}>
            {searchResult.length !== 0 ? (
              <>
                {searchResult.map((item, idx) => (
                  <MDBox
                    key={idx}
                    sx={{
                      // border: "1px solid #f57c00",
                      cursor: "pointer",
                      padding: "0.5rem",

                      "&:hover": {
                        backgroundColor: "#331b03",
                        // opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                    onClick={() => handleEventDetails("otherEvent", item)}
                  >
                    <MDTypography fontSize="1rem">
                      <TbCalendarStats color="#969696" />
                      &nbsp;
                      {item.eventName}
                    </MDTypography>
                  </MDBox>
                ))}
              </>
            ) : (
              <MDBox
                sx={{
                  height: "500px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MDBox sx={{ textAlign: "center" }}>
                  <MDTypography>
                    {searchLoading ? "No events found" : "Start searching..."}
                  </MDTypography>
                </MDBox>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
}

//Card backup

{
  /* <Card
  key={idx}
  sx={{
    margin: 0,
    cursor: "pointer",
    width: "275px",
    height: "400px",
    marginRight: "20px",
    marginLeft: "20px",
  }}
  onClick={() => handleEventDetails(item)}
 
>
  <CardMedia
    sx={{ margin: 0, borderRadius: "10px 10px 0px 0px" }}
    component="img"
    height="360"
    image={item.photo}
    alt="Paella dish"
  ></CardMedia>
  <MDBox
    sx={{
      position: "absolute",
      top: 10,
      marginLeft: "5px",
      width: "90px",
      backgroundColor: "#000000",
      opacity: "60%",
      borderRadius: "15px",
      textAlign: "center",
    }}
  >
    <MDTypography fontSize="small">{item.eventDate}</MDTypography>
  </MDBox>

  <MDBox sx={{ padding: 1 }}>
    <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
      <MDBox
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
       
        <MDBox marginLeft="4px" width="120px">
          <MDTypography fontWeight="bold" fontSize="12px">
            {item.eventName}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox
        sx={{
          display: "flex",
          
          alignItems: "center",
        }}
      >
        <MDTypography color="white" fontWeight="bold" fontSize="small">
          {item.originalPrice === "0" ? "Free" : parseFloat(item.originalPrice).toFixed(4)}
        </MDTypography>

        <MDAvatar src={SinghCoin} size="xs" />
      </MDBox>
    </MDBox>
  </MDBox>
</Card>; */
}
