import { create } from "ipfs-http-client";
import axios from "axios";
import { eventContractAddress } from "utils/config";
import { singhCoinAddress } from "utils/config";
import { baseUrl } from "../helpers/baseUrl";
import ERC20ABI from "../assets/abi/ERC20.json";
import ticketPlatformABI from "../assets/abi/ticketPlatform.json";

import Web3 from "web3";
import Swal from "sweetalert2";

export const getUserTokenBalance = async (web3, walletAddress) => {
  // console.log("add", walletAddress);

  try {
    const tokenContract = new web3.eth.Contract(ERC20ABI, singhCoinAddress);

    // console.log("tokenContract", tokenContract);

    const bal = await tokenContract.methods.balanceOf(walletAddress).call();

    // console.log("testBal", web3.utils.fromWei(bal));

    console.log("bal", bal);

    return { tokenBal: web3.utils.fromWei(bal) };
  } catch (err) {
    console.log("error", err);
    return err;
  }
};

export async function postMetaData(metaData) {
  // console.log("metaData", metaData);

  try {
    const response = await axios.post(`${baseUrl}/events/meta-data/`, metaData);
    console.log("metaData", response);
    return response.data;
  } catch (err) {
    return err;
  }
}

// export async function newMetaData(metaData) {
//   // console.log("metaData", metaData);

//   try {
//     const client = create(new URL("https://singhapp.infura-ipfs.io/ipfs"));

//     // console.log("client", client);
//     const { cid } = await client.add(metaData);

//     // console.log("cidValue", cid);

//     // return response.data;
//   } catch (err) {
//     console.log("err", err);
//     // return err.response;
//   }
// }

export const mint = async (web3, address, numberOfTickets, price, eventDate, metaDataUri) => {
  try {
    const mintContract = new web3.eth.Contract(ticketPlatformABI, eventContractAddress);
    const etherPrice = await web3.utils.toWei(price, `ether`);
    // console.log("values", web3);
    // console.log("values", address);
    // console.log("values", numberOfTickets);
    // console.log("values", typeof metaDataUri);
    // console.log("values", etherPrice);
    // console.log("values", eventDate);

    const newMintContract = await mintContract.methods
      .mint(
        address, // walletAddress
        numberOfTickets,
        numberOfTickets, // totalticket
        metaDataUri, // metaDataUri
        etherPrice,
        eventDate
      )
      .send({
        from: address, // walletAddress
      });

    // console.log("newContract", newMintContract);
    return newMintContract;
  } catch (err) {
    console.log("errr", err);
    return err;
  }
};

export async function getTokenList(web3) {
  try {
    const contractTokenList = new web3.eth.Contract(ticketPlatformABI, eventContractAddress);

    const tokenList = await contractTokenList.methods.getTokenIds().call();

    return tokenList;
  } catch (err) {
    console.log("err", err);
    return err;
  }
}

export async function getBuyTicketDetails(web3, tokenId) {
  try {
    const contractTicketDetails = new web3.eth.Contract(ticketPlatformABI, eventContractAddress);

    // console.log("contreactTicketDetails", contractTicketDetails);

    const newTicketsDetails = await contractTicketDetails.methods.ticketsDetails(tokenId).call();

    // console.log("newTicketsDetails", newTicketsDetails);

    return newTicketsDetails;
  } catch (err) {
    console.log("err", err);
    return err;
  }
}

export async function buyTickets(web3, walletAddress, amount, tokenId, numberOfTickets) {
  try {
    const weiAmount = await web3.utils.toWei(amount.toString(), `ether`);

    const contractBuyTickets = new web3.eth.Contract(ERC20ABI, singhCoinAddress);

    const checkAllowance = await contractBuyTickets.methods
      .allowance(walletAddress, eventContractAddress)
      .call();

    if (Number(checkAllowance) < Number(weiAmount)) {
      const approveCall = await contractBuyTickets.methods
        .approve(eventContractAddress, weiAmount)
        .send({
          from: walletAddress, // walletAddress
        });
      if (!approveCall.transactionHash) {
        return "Transaction Failed";
      }
    }
    const contractBuyTicketsNew = await new web3.eth.Contract(
      ticketPlatformABI,
      eventContractAddress
    );
    // console.log("contractBuyTicketsNew", contractBuyTicketsNew);
    // console.log("valueTokenID", tokenId);
    // console.log("valueNumberOfTickets", numberOfTickets);
    // console.log("valueAmount", weiAmount);
    const newBuyTicket = await contractBuyTicketsNew.methods
      .buyTickets("SINGH", tokenId, numberOfTickets, weiAmount)
      .send({
        from: walletAddress, // walletAddress
      });
    // console.log("newBuyTIcket", newBuyTicket);
    return newBuyTicket;
  } catch (err) {
    console.log("err", err);
    return err;
  }
}

// export async function getMyEventsDetails(walletAddress) {
//   /// replace with new Api call getMyCreatedEvents

//   // const newRpc = "https://bsc.getblock.io/mainnet/?api_key=7f26efe8-9aca-4f8f-a611-f8e82dabc3a3";

//   const newRpc = "https://bsc.getblock.io/testnet/?api_key=ea05c7d6-d013-4d6a-95ed-ee6e8cd8e0c7";

//   const web3 = new Web3(newRpc);

//   // console.log("web3", web3);

//   // console.log("address", walletAddress);

//   try {
//     const contractMyEventDetails = new web3.eth.Contract(ticketPlatformABI, eventContractAddress);
//     console.log("contractMyEventDetails", contractMyEventDetails);
//     let currentBlock = await web3.eth.getBlockNumber();

//     // console.log("curBlock", currentBlock);

//     // let eventsArr = [];

//     // for (let i = 21774352; i < currentBlock; i += 5000) {
//     // console.log("i", i);
//     const newMyEventDetails = await contractMyEventDetails
//       .getPastEvents(
//         "createTickets",
//         {
//           filter: { creator: walletAddress },
//           fromBlock: 21973456,
//           toBlock: currentBlock,
//         }
//         // function (error, events) {
//         //   console.log(events);
//         // }
//       )
//       .then(function (events) {
//         // console.log(events); // same results as the optional callback above

//         // if (events.length !== 0) {
//         //   eventsArr.push(...events);
//         // }
//         console.log("my", events);
//         return events;
//       });
//     // }

//     // console.log("events", eventsArr);

//     // console.log("newMyEventDetails", newMyEventDetails);

//     return newMyEventDetails;
//   } catch (err) {
//     console.log("err", err);
//     return err;
//   }
// }

export async function getMyEventList(creatorWalletAddress) {
  /// replaced otherEventsContract call with new Api call getAllEventList

  try {
    const response = await axios.get(`${baseUrl}/events/mycreatedevents/${creatorWalletAddress}`);
    console.log("MyLisRes", response);
    return response.data.listOfMyEvents; // will change on nxt push
  } catch (err) {
    console.log("err", err);
    return err;
  }
}

// export async function getOtherEventsDetails() {
//   /// replace with new Api call getAllEventList

//   // const newRpc = "https://bsc.getblock.io/mainnet/?api_key=7f26efe8-9aca-4f8f-a611-f8e82dabc3a3";

//   const newRpc = "https://bsc.getblock.io/testnet/?api_key=ea05c7d6-d013-4d6a-95ed-ee6e8cd8e0c7";

//   const web3 = new Web3(newRpc);

//   // console.log("web3", web3);

//   // console.log("address", walletAddress);

//   // console.log("web3", web3);
//   try {
//     const contractOtherEventsDetails = new web3.eth.Contract(
//       ticketPlatformABI,
//       eventContractAddress
//     );

//     let currentBlock = await web3.eth.getBlockNumber();

//     // console.log("currentCbLock", currentBlock);

//     // let otherEventsArr = [];

//     // for (let i = 21774352; i < currentBlock; i += 5000) {
//     const newOtherEventsDetails = await contractOtherEventsDetails
//       .getPastEvents(
//         "createTickets",
//         {
//           // filter: { creator: "0x387084aAeCc71CF7D00e444e9D55ca9667343D41" },
//           fromBlock: 21973456,
//           toBlock: currentBlock,
//         }
//         // function (error, events) {
//         //   console.log(events);
//         // }
//       )
//       .then(function (events) {
//         // console.log(events); // same results as the optional callback above
//         // if (events.length !== 0) {
//         //   otherEventsArr.push(...events);
//         // }
//         return events;
//       });
//     // }

//     // console.log("test", newOtherEventsDetails);

//     return newOtherEventsDetails;
//   } catch (err) {
//     console.log("err", err);
//     return err;
//   }
// }

export async function getAllEventList() {
  /// replaced otherEventsContract call with new Api call getAllEventList

  try {
    const response = await axios.get(`${baseUrl}/events/eventslists`);
    console.log("allLisRes", response);
    return response.data;
  } catch (err) {
    console.log("err", err);
    return err;
  }
}

// export async function buyEventTicketsList(web3, walletAddress) {
//   console.log("walletAdd", walletAddress);

//   try {
//     const contractTicketDetails = new web3.eth.Contract(ticketPlatformABI, eventContractAddress);
//     let currentBlock = await web3.eth.getBlockNumber();
//     console.log("currentCbLock", currentBlock);
//     let boughtTicketsArr = [];
//     console.log("contractTicketDetails", contractTicketDetails);
//     for (let i = 21774352; i < currentBlock; i += 5000) {
//       const newTicketDetails = await contractTicketDetails
//         .getPastEvents("buyEventsTickets", {
//           filter: { buyer: walletAddress }, // Using an array means OR: e.g. 20 or 23
//           fromBlock: i,
//           toBlock: i + 5000,
//         })
//         .then(function (ticket) {
//           console.log(ticket); // same results as the optional callback above
//           if (ticket.length !== 0) {
//             boughtTicketsArr.push(...ticket);
//           }
//           // return events;
//         });
//     }
//     // console.log("newTicketDetails", newTicketDetails);
//     console.log("boughtTicketsArr", boughtTicketsArr);

//     return boughtTicketsArr;
//   } catch (err) {
//     console.log("err", err);
//     return err.response;
//   }
// }

// export async function buyEventTicketsListApi(walletAddress) {
//   // console.log("walletAdd", walletAddress);

//   // new Api to add Your Tickets

//   try {
//     const boughtEventTicketsList = await axios.post(
//       `https://staging-api.singhcoin.io/events/buytickets`,
//       walletAddress
//     );

//     console.log("boughtTickets", boughtEventTicketsList);

//     return boughtEventTicketsList.data;
//   } catch (err) {
//     console.log("err", err);
//     return err;
//   }
// }

export async function boughtEventTicketsListApi(walletAddress) {
  // console.log("walletAdd", walletAddress);

  // new Api to add Your Tickets

  try {
    const boughtEventTicketsList = await axios.get(
      `${baseUrl}/events/yourtickets/${walletAddress}/?pageNumber=0`
    );

    console.log("boughtTickets", boughtEventTicketsList);

    return boughtEventTicketsList.data;
  } catch (err) {
    console.log("err", err);
    return err;
  }
}

export async function getIpfsData(metaDataUri) {
  // console.log("metaDataURI", metaDataUri);

  try {
    const ipfsData = await axios.get(`${metaDataUri}`);

    // console.log("ipfsData", ipfsData);
    return ipfsData.data;
  } catch (err) {
    console.log("errrrrrrr", err);
    return err;
  }
}

export async function getChangedPrice(web3, walletAddress, tokenId, price) {
  // console.log("tokenId", tokenId);
  // console.log("price", price);

  try {
    const weiPrice = await web3.utils.toWei(price.toString(), `ether`);

    const contractPrice = new web3.eth.Contract(ticketPlatformABI, eventContractAddress);

    const changedPrice = await contractPrice.methods.changePrice(tokenId, weiPrice).send({
      from: walletAddress,
    });

    console.log("changedPrice", changedPrice);

    // return
  } catch (err) {
    console.log("errrrrrrr", err);
    return err;
  }
}

export async function getUpdatedPrice(web3, tokenId) {
  try {
    const contractUpdatedPrice = new web3.eth.Contract(ticketPlatformABI, eventContractAddress);
    const updatedPrice = await contractUpdatedPrice.methods.getTicketPrice(tokenId).call();
    const etherPrice = await web3.utils.fromWei(updatedPrice.toString(), `ether`);
    return etherPrice;
  } catch (err) {
    console.log("errrrrrrr", err);
    return err;
  }
}

export async function getCreator(web3, tokenId) {
  try {
    const contractCreator = new web3.eth.Contract(ticketPlatformABI, eventContractAddress);
    const fetchCreator = await contractCreator.methods.getCreator(tokenId).call();
    return fetchCreator;
  } catch (err) {
    console.log("errrrrrrr", err);
    return err;
  }
}

export async function getMyTicket(web3, walletAddress, tokenId) {
  try {
    const contractMyTicket = new web3.eth.Contract(ticketPlatformABI, eventContractAddress);
    const myTicket = await contractMyTicket.methods.getMyTicket(walletAddress, tokenId).call();
    return myTicket;
  } catch (err) {
    console.log("errrrrrrr", err);
    return err;
  }
}

export async function createEventApi(eventDetails) {
  //replace api call  creatEvent

  try {
    const response = await axios.post(`${baseUrl}/events/createevent`, eventDetails);
    return response;
  } catch (err) {
    console.log("errrrrrrr", err);
    return err;
  }
}

export async function addMail(buyerDetails) {
  // this call with merge with bellow call
  try {
    const response = await axios.post(`${baseUrl}/events/setmails`, buyerDetails);
    return response;
  } catch (err) {
    console.log("errrrrrrr", err);
    return err;
  }
}

export async function postBuyerDetails(buyerDetails) {
  // this call with merge with above call
  try {
    const response = await axios.post(`${baseUrl}/events/buyevents`, buyerDetails);
    return response;
  } catch (err) {
    console.log("errrrrrrr", err);
    return err;
  }
}

export async function getSalesDetails(creatorAddress, tokenId) {
  console.log("data", creatorAddress, tokenId);
  try {
    const response = await axios.get(`${baseUrl}/events/getemaillist/${creatorAddress}/${tokenId}`);
    return response.data;
  } catch (err) {
    console.log("errrrrrrr", err);
    return err;
  }
}

export async function getQuantity(web3, walletAddress, tokenId) {
  console.log(web3);
  console.log(walletAddress);
  console.log(tokenId);
  try {
    const contractMyTicket = new web3.eth.Contract(ticketPlatformABI, eventContractAddress);
    const myTicket = await contractMyTicket.methods.getMyTicket(walletAddress, tokenId).call();
    console.log("balance", myTicket);
    return myTicket;
  } catch (err) {
    console.log("errrrrrrr", err);
    return err;
  }
}

export async function getSearch(searchValue, idx) {
  try {
    const response = await axios.get(`${baseUrl}/events/search/${searchValue}/?pageNumber=${idx}`);
    // console.log("searchRes", response);
    return response.data;
  } catch (err) {
    console.log("errrrrrrr", err);
    return err;
  }
}

// check Organiser -
// getOrganiser(tokenid)

// add organiser
// addOrganiser(uint tokenId, address newOrganiser)
// call from only event owner

export async function addEventOrganisers(web3, tokenId, walletAddress, ownerWalletAddress) {
  try {
    const contract = new web3.eth.Contract(ticketPlatformABI, eventContractAddress);
    const addOrganisersRes = await contract.methods
      .addOrganiser(tokenId, walletAddress)
      .send({ from: ownerWalletAddress });
    console.log("addOrganisersRes", addOrganisersRes);
    return addOrganisersRes;
  } catch (err) {
    console.log("errrrrrrr", err);
    return err;
  }
}

export async function getEventOrganisers(web3, tokenId) {
  try {
    const contract = new web3.eth.Contract(ticketPlatformABI, eventContractAddress);
    const getOrganisersRes = await contract.methods.getOrganiser(tokenId).call();
    return getOrganisersRes;
  } catch (err) {
    console.log("errrrrrrr", err);
    return err;
  }
}

export async function getEventsByStatus(status, idx) {
  try {
    const response = await axios.get(
      `${baseUrl}/events/geteventbystatus/${status}/?pageNumber=${idx}`
    );
    // console.log("getEventsByStatus", response);
    return response.data;
  } catch (err) {
    console.log("errrrrrrr", err);
    Swal.fire("Something went wrong with server", "Please try again later", "error");
    return [];
  }
}

export async function getMyEvents(walletAddress, idx) {
  try {
    const response = await axios.get(
      `${baseUrl}/events/mycreatedevents/${walletAddress}/?pageNumber=${idx}`
    );
    // console.log("getEventsByStatus", response);
    return response.data;
  } catch (err) {
    console.log("errrrrrrr", err);
    Swal.fire("Something went wrong with server", "Please try again later", "error");
    return [];
  }
}

export async function getEventsById(tokenId) {
  try {
    const response = await axios.get(`${baseUrl}/events/geteventsbyId/${tokenId}`);
    // console.log("getEventsById", response);
    return response.data;
  } catch (err) {
    console.log("errrrrrrr", err);
    // Swal.fire("Something went wrong with server", "Please try again later", "error");
    // return [];
    return err;
  }
}

export async function checkTicketStatus(checkData) {
  try {
    const response = await axios.post(`${baseUrl}/events/addverification`, checkData);
    console.log("checkTicketStatus", response);
    return response.data;
  } catch (err) {
    console.log("errrrrrrr", err);
    Swal.fire("Something went wrong with server", "Please try again later", "error");
    return [];
  }
}

export async function deactivateEvent(eventData) {
  try {
    const response = await axios.post(`${baseUrl}/events/deactiveEvent`, eventData);
    console.log("deactivateEvent", response);
    return response.data;
  } catch (err) {
    console.log("errrrrrrr", err);
    // Swal.fire("Something went wrong with server", "Please try again later", "error");
    // return [];
    return err;
  }
}
