import MDBox from "components/MDBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import test1 from "./1.png";
import test2 from "./2.png";
import test3 from "./3.png";
import test4 from "./4.png";
import test5 from "./5.png";
import test6 from "./6.png";
import test7 from "./7.png";
import test8 from "./8.png";
import test9 from "./9.png";
import test10 from "./10.png";

import testm1 from "./m1.png";
import testm2 from "./m2.png";
import testm3 from "./m3.png";
import testm4 from "./m4.png";
import testm5 from "./m5.png";
import testm6 from "./m6.png";
import testm7 from "./m7.png";
import testm8 from "./m8.png";
import testm9 from "./m9.png";
import testm10 from "./m10.png";

export default function Banner() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    laptop: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 330 },
      items: 1,
    },
    mobileSmall: {
      breakpoint: { max: 320, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const [testImg, setTestImg] = useState([
    {
      img: isTabletOrMobile ? testm1 : test1,
    },
    // {
    //   img: isTabletOrMobile ? testm2 : test2,
    // },
    // {
    //   img: isTabletOrMobile ? testm3 : test3,
    // },
    {
      img: isTabletOrMobile ? testm4 : test4,
    },
    // {
    //   img: isTabletOrMobile ? testm5 : test5,
    // },
    {
      img: isTabletOrMobile ? testm6 : test6,
    },
    {
      img: isTabletOrMobile ? testm7 : test7,
    },
    {
      img: isTabletOrMobile ? testm8 : test8,
    },
    {
      img: isTabletOrMobile ? testm9 : test9,
    },
    {
      img: isTabletOrMobile ? testm10 : test10,
    },
  ]);

  const handleData = (data) => {
    console.log("data", data);
  };

  return (
    <MDBox sx={{ marginBottom: "5rem" }}>
      {/* <DashboardNavbar /> */}

      <Carousel
        // responsive={responsive}
        centerMode={isDesktopOrLaptop ? true : false}
        // containerClass="carousel-container"
        // partialVisbile={true}
        autoPlay={true}
        // infinite={true}
        infiniteLoop={true}
        showStatus={false}
        showThumbs={false}
        // height="300px"
      >
        {testImg.map((item, idx) => (
          <MDBox
            sx={{
              cursor: "pointer",
              // margin: "auto",
              // width: "1000px",
              height: "400px",
              // width: "1000px",
              // marginRight: "10px",
              margin: { sm: "none", md: "0.5rem" },
              // padding: "0.5rem",
              // border: "1px solid red",
            }}
            onClick={() => handleData(item)}
          >
            <img src={item.img} style={{ borderRadius: "15px", width: "100%", height: "100%" }} />
          </MDBox>
        ))}
      </Carousel>

      {/* <MDBox
        sx={{
          // border: "1px solid red",
          position: "absolute",
          height: "8rem",
          width: "100%",
          top: 360,
          backgroundImage: "linear-gradient(180deg, transparent, transparent, #121212)",
        }}
      ></MDBox> */}
    </MDBox>
  );
}
