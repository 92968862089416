import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loader from "loader/loader";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getIpfsData } from "services/contract";
// import { buyEventTicketsListApi } from "services/contract";
import { getBuyTicketDetails } from "services/contract";
import { buyEventTicketsList } from "services/contract";
import { useMediaQuery } from "react-responsive";

import { useWallet } from "store/wallet";
import demoTicket from "./demoTicket.jpg";
import Footer from "examples/Footer";
// import QRCode from "qrcode";
import QRCode from "react-qr-code";
import { boughtEventTicketsListApi } from "services/contract";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import { FaRegCalendarAlt } from "react-icons/fa";
import { MdOutlinePlace } from "react-icons/md";
import { MdOutlineCategory } from "react-icons/md";

export default function MyTickets() {
  let navigate = useNavigate();
  const { web3, isWeb3Enabled, address } = useWallet();
  const [qrData, setQrData] = useState("");

  const [myTickets, setMyTickets] = useState([]);

  // const [myTicketsArr, setMyTicketsArr] = useState([]);

  const [ticketLoader, setTicketLoader] = useState(true);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  useEffect(() => {
    if (Object.keys(web3).length != 0 && address != undefined) {
      // fetchBuyEventTickets();
      fetchBoughtEventTicketsApi();
    }
    // else {
    //   Swal.fire({
    //     icon: "warning",
    //     text: "Please connect your Metamask Wallet",
    //   }).then(() => {
    //     navigate("/");
    //   });
    // }
  }, [address]);

  const fetchBoughtEventTicketsApi = async () => {
    setTicketLoader(true);

    const boughtTickets = await boughtEventTicketsListApi(address);
    console.log("boughtTickets", boughtTickets);
    setMyTickets(boughtTickets);
    setTicketLoader(false);
  };

  // const generateQrCode = async () => {
  //   try {
  //     const res = await QRCode.toDataURL(`http://localhost:3000/testqr`);
  //     console.log(res);
  //     setQrData(res);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // generateQrCode();

  // const fetchBuyEventTickets = async () => {
  //   setTicketLoader(true);
  //   const buyEventTicketsResp = await buyEventTicketsList(web3, address);

  //   console.log("buyEventTicketResp", buyEventTicketsResp);
  //   for (let ticket of buyEventTicketsResp) {
  //     const myTicketDetails = await getBuyTicketDetails(web3, ticket.returnValues.tokenId);
  //     console.log("myTicketDetails", myTicketDetails);
  //     ticket.returnValues["metaDataUri"] = myTicketDetails.metaDataUri;
  //     ticket.returnValues["eventDate"] = myTicketDetails.eventDate;
  //   }

  //   for (let newTicket of buyEventTicketsResp) {
  //     const myNewTicketDetails = await getIpfsData(newTicket.returnValues.metaDataUri);
  //     console.log("myTicketDetails", myNewTicketDetails);
  //     newTicket.returnValues["name"] = myNewTicketDetails.name;
  //     newTicket.returnValues["description"] = myNewTicketDetails.description;
  //     newTicket.returnValues["photo"] = myNewTicketDetails.photo;
  //     newTicket.returnValues["venue"] = myNewTicketDetails.venue;
  //     newTicket.returnValues["blockChain"] = myNewTicketDetails.blockChain;
  //   }

  //   // console.log("buyEventTickets", buyEventTicketsResp);
  //   setMyTickets(buyEventTicketsResp);
  //   setTicketLoader(false);
  // };

  return (
    <>
      <DashboardNavbar />

      <MDBox
        className="MyTickets"
        sx={{
          // display: "flex",
          // justifyContent: "space-between",
          marginRight: isTabletOrMobile ? "5%" : "10%",
          marginLeft: isTabletOrMobile ? "5%" : "10%",
          marginBottom: "2rem",
          // alignItems: "center",
        }}
      >
        <MDBox>
          <MDTypography fontWeight="bold" variant="h3">
            Your Tickets
          </MDTypography>
        </MDBox>
        {/* <MDBox sx={{ cursor: "pointer" }}>
          {myEvents.length > 4 && <MDTypography variant="h6">See All</MDTypography>}
        </MDBox> */}
      </MDBox>

      {myTickets && myTickets.length !== 0 ? (
        <>
          {myTickets.map((item, idx) => {
            return (
              <Card
                key={idx}
                sx={{
                  marginRight: isTabletOrMobile ? "5%" : "10%",
                  marginLeft: isTabletOrMobile ? "5%" : "10%",
                  marginBottom: "5rem",
                  padding: "0.5rem",
                }}
              >
                <Grid container sx={{}}>
                  <Grid
                    item
                    container
                    xs={12}
                    md={3}
                    sx={{
                      justifyContent: "center",
                      marginBottom: { xs: "1rem", md: "0" },
                    }}
                  >
                    <MDBox
                      sx={{
                        height: "300px",
                        width: "300px",
                      }}
                    >
                      <img src={item.photo} style={{ width: "100%", height: "100%" }} alt="logo" />
                    </MDBox>
                  </Grid>

                  <Grid
                    sx={{
                      padding: "1rem",
                      alignItems: "center",
                    }}
                    item
                    container
                    xs={12}
                    md={5}
                  >
                    <MDBox sx={{}}>
                      <MDBox sx={{ marginBottom: "1rem" }}>
                        <MDTypography fontWeight="bold" fontSize="35px" lineHeight="50px">
                          {item.eventName}
                        </MDTypography>
                      </MDBox>
                      <MDBox>
                        <MDTypography fontWeight="bold">
                          <MdOutlineCategory />
                          &nbsp;
                          {item.category}
                        </MDTypography>
                      </MDBox>
                      <MDBox sx={{}}>
                        <MDTypography fontWeight="bold">
                          <FaRegCalendarAlt />
                          &nbsp;
                          <Moment format="ddd,DD MMMM, YYYY">{new Date(item.eventDate)}</Moment>
                        </MDTypography>
                      </MDBox>
                      <MDBox>
                        <MDTypography fontWeight="bold">
                          <MdOutlinePlace />
                          &nbsp;
                          {item.venue}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </Grid>

                  <Grid sx={{ borderLeft: "1px dashed white" }} item container xs={12} md={4}>
                    <Grid item container sx={{ alignItems: "center" }}>
                      <Grid item xs={12} sx={{ textAlign: "center" }}>
                        <MDBox sx={{ display: "flex", justifyContent: "center" }}>
                          <QRCode
                            size={150}
                            // https://singhcoin.io/app/?data=0/0x58d3bdb3ae20548f979aeb90c05e3b2cb72012a6
                            // https://events.singhcoin.io/?data=tokenId/walletAddresscreator
                            value={`https://events.singhcoin.io/verify/?tokenId=${item.tokenId}&buyerWalletAddress=${address}`}
                          />
                        </MDBox>
                        <MDBox>
                          <MDTypography fontWeight="light" fontSize="15px">
                            Scan QR-code using Metamask
                          </MDTypography>
                          {/* <MDTypography fontWeight="light" fontSize="15px" lineHeight="15px">
                            or Singh mobile app
                          </MDTypography> */}
                        </MDBox>
                      </Grid>
                    </Grid>
                    <Grid item container sx={{ alignItems: "start" }}>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <MDTypography color="white" fontWeight="bold" fontSize="40px">
                          {item.numberOfTickets}
                        </MDTypography>
                        <MDTypography color="white" fontSize="20px">
                          Tickets
                        </MDTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            );
          })}
        </>
      ) : (
        <MDBox>
          {ticketLoader ? (
            <MDBox
              sx={{
                // border: "1px solid red",
                height: "500px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </MDBox>
          ) : (
            <MDBox sx={{ textAlign: "center", height: "500px" }}>
              <MDTypography>There are no bought tickets</MDTypography>
            </MDBox>
          )}
        </MDBox>
      )}
      <Footer />
    </>
  );
}
