import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSalesDetails } from "services/contract";
import fakeData from "./fakeData";
import { useSearchParams } from "react-router-dom";
import MDTypography from "components/MDTypography";
import Moment from "react-moment";
import { useWallet } from "store/wallet";
import { getQuantity } from "services/contract";

export default function OrderDetails() {
  const { web3, isWeb3Enabled, address } = useWallet();
  const [searchParams] = useSearchParams();
  // const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState([]);

  // console.log("cd", id);
  // console.log("SP", searchParams.get("creatorAddress"));
  // console.log("SP", searchParams.get("tokenId"));

  const fetchSalesDetails = async () => {
    const salesDetails = await getSalesDetails(
      searchParams.get("creatorAddress"),
      searchParams.get("tokenId")
    );
    console.log("salesDetails", salesDetails);

    // let newOrderDetails = [];

    // for (let item of salesDetails.creatorFind.Emails) {
    //   newOrderDetails.push({
    //     ...item,
    //     quantity: await getQuantity(web3, item.walletAddress, item.tokenId),
    //   });
    // }

    setOrderDetails(salesDetails.creatorFind[0].Emails);
  };

  // params creator address for order details

  useEffect(() => {
    fetchSalesDetails();
  }, [address]);

  const dataTableData = {
    columns: [
      { Header: "Order", accessor: "tokenId" },
      // { Header: "Event", accessor: "imo" },
      { Header: "Buyer", accessor: "email" },
      {
        Header: "Date",
        accessor: "buyTime",
        Cell: (cell) => (
          <>
            <MDTypography variant="sm" color="white" component="div">
              <Moment format="Do MMM, YYYY">{Number(cell.row.values.buyTime)}</Moment>
            </MDTypography>
          </>
        ),
      },
      {
        Header: "Quantity",
        accessor: "ticketQuantity",
      },
      { Header: "Price", accessor: "price" },
    ],

    rows: orderDetails ? orderDetails : [],
  };

  return (
    <>
      <DashboardNavbar />
      <MDBox sx={{ marginRight: "10%", marginLeft: "10%" }}>
        <MDTypography fontWeight="bold" variant="h4">
          Order List
        </MDTypography>
        {orderDetails.length ? (
          <Card>
            <DataTable table={dataTableData} />
          </Card>
        ) : (
          <MDBox sx={{ height: "315px", marginTop: "3rem" }}>
            <MDBox sx={{ width: "50%", margin: "auto", textAlign: "center" }}>
              <MDTypography fontWeight="bold">No orders recieved yet !</MDTypography>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
      <Footer />
    </>
  );
}
