import React, { createContext, useCallback, useState, useContext, useMemo, useEffect } from "react";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Swal from "sweetalert2";

const WALLET_CONNECTED_ITEM = "@Mealswap/walletProviderConnected";

const WalletContext = createContext({});

export const WalletProvider = ({ children }) => {
  const [web3, setWeb3] = useState({});
  const [address, setAddress] = useState();
  const [chainId, setChainId] = useState();

  const isWeb3Enabled = useMemo(() => {
    if (web3.eth) {
      return true;
    }
    return false;
  }, [web3]);

  const enableWeb3 = useCallback(
    async (providerAPI) => {
      try {
        if (!isWeb3Enabled) {
          if (window[providerAPI]) {
            await window[providerAPI].request({
              method: "eth_requestAccounts",
            });
            setWeb3(new Web3(window[providerAPI]));
            const chainId = await window[providerAPI].request({ method: "eth_chainId" });
            setChainId(Web3.utils.hexToNumber(chainId));
            localStorage.setItem(WALLET_CONNECTED_ITEM, providerAPI);
          }
          // else if (providerAPI === "walletconnect") {
          //   const provider = new WalletConnectProvider({
          //     rpc: {
          //       56: "https://bsc-dataseed.binance.org/",
          //     },
          //     chainId: 56,
          //   });
          //   await provider.enable();
          //   setWeb3(new Web3(provider));
          //   localStorage.setItem(WALLET_CONNECTED_ITEM, providerAPI);
          // }
          else {
            // throw new Error("Could not find the selected provider.");
            Swal.fire({
              icon: "warning",
              title: "Metamask is not installed",
              text: "Please install metamask browser extension to connect metamask wallet",
              confirmButtonText:
                '<a href="//metamask.io/download" target="_blank" rel="noopener noreferrer">Install</a> ',
            });
          }
        }
      } catch (error) {
        console.error(error);
        // toast("An error has occurred", error.message);
        // toast({
        //   status: 'error',
        //   description: error.message,
        //   title: 'Error',
        //   position: 'top',
        //   duration: 5000,
        // });
      }
    },
    [isWeb3Enabled]
  );

  useEffect(() => {
    (async () => {
      if (isWeb3Enabled) {
        const [account] = await web3.eth.getAccounts();
        setAddress(account);
      }
    })();
  }, [isWeb3Enabled, web3.eth]);

  useEffect(() => {
    const walletProvider = localStorage.getItem(WALLET_CONNECTED_ITEM);
    if (walletProvider) {
      setTimeout(() => {
        enableWeb3(walletProvider);
      }, 1000);
    }
  }, [enableWeb3]);

  return (
    <WalletContext.Provider
      value={{
        isWeb3Enabled,
        web3,
        enableWeb3,
        address,
        chainId,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

/**
 * @typedef {Function} EnableWeb3
 * @param {string} providerAPI - The provider injected window api
 * @returns {void}
 */

/**
 * @typedef {Object} WalletProps
 * @property {boolean} isWeb3Enabled - True if wallet is connected already
 * @property {Web3} web3 - The Web3 object
 * @property {EnableWeb3} enableWeb3 - Used to enable web3 (connect the wallet)
 * @property {string} address - User's address
 */

/**
 * useWallet hook
 * @returns {WalletProps}
 */
export const useWallet = () => {
  const context = useContext(WalletContext);

  if (!context) {
    throw new Error("useWallet must be used within an WalletContext Provider");
  }

  return context;
};
