import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
export default function Loader() {
  return (
    <>
      <MDBox color="#FF9607">
        <CircularProgress color="inherit" />
      </MDBox>
    </>
  );
}
