/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";

function Footer({ company, links }) {
  const { href, name } = company;
  const { size } = typography;

  const renderLinks = () =>
    links.map((link) => (
      <MDBox key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href} target="_blank">
          <MDTypography variant="button" fontWeight="regular" color="white">
            {link.name}
          </MDTypography>
        </Link>
      </MDBox>
    ));

  // const renderIcons = () =>
  //   links.map((link) => (
  //     <MDBox key={link.name} component="li" px={2} lineHeight={1}>
  //       <Link href={link.href} target="_blank">
  //       {link.name}
  //       </Link>
  //     </MDBox>
  //   ));

  const handlefb = () => {
    href = "https://www.facebook.com/singhtheapp";
  };

  return (
    <MDBox
      height="100px"
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-around"
      alignItems="center"
      px={1.5}
      sx={{
        marginTop: "10rem",
        backgroundColor: "#1a1a1a",
      }}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="#fff"
        fontSize={size.sm}
        px={1.5}
      >
        &copy; {new Date().getFullYear()}, made with
        <MDBox fontSize={size.md} color="#fff" mb={-0.5} mx={0.25}>
          <Icon color="inherit" fontSize="inherit">
            favorite
          </Icon>
        </MDBox>
        by
        <Link href={href} target="_blank">
          <MDTypography variant="button" fontWeight="medium">
            &nbsp;Singh&nbsp;
          </MDTypography>
        </Link>
        for a better web.
      </MDBox>
      <MDBox
        component="ul"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        {/* {renderIcons()} */}
        <MDBox
          px={2}
          lineHeight={1}
          sx={{ cursor: "pointer" }}
          component="a"
          href="https://www.facebook.com/singhtheapp"
          fontSize="25px"
        >
          <FacebookIcon sx={{ color: "#fff" }} />
        </MDBox>
        <MDBox
          component="a"
          href="https://www.instagram.com/singhtheapp/"
          px={2}
          lineHeight={1}
          fontSize="25px"
        >
          <InstagramIcon sx={{ color: "#fff" }} />
        </MDBox>
        <MDBox
          component="a"
          href="https://t.me/group_singhtheapp"
          px={2}
          lineHeight={1}
          fontSize="25px"
        >
          <TelegramIcon sx={{ color: "#fff" }} />
        </MDBox>
        <MDBox
          component="a"
          href="https://www.linkedin.com/company/singhtheapp/"
          px={2}
          lineHeight={1}
          fontSize="25px"
        >
          <LinkedInIcon sx={{ color: "#fff", fontSize: "inherit" }} />
        </MDBox>
        <MDBox
          component="a"
          href="https://twitter.com/SinghTheApp"
          px={2}
          lineHeight={1}
          fontSize="25px"
        >
          <TwitterIcon sx={{ color: "#fff", fontSize: "inherit" }} />
        </MDBox>
        <MDBox
          component="a"
          href="https://www.pinterest.co.uk/singhtheapp/_saved/"
          px={2}
          lineHeight={1}
          fontSize="25px"
        >
          <PinterestIcon sx={{ color: "#fff", fontSize: "inherit" }} />
        </MDBox>
      </MDBox>

      <MDBox
        component="ul"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        {renderLinks()}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: { href: "/", name: "Singh" },
  links: [
    {
      href: "https://docs.google.com/forms/d/e/1FAIpQLSernTaM_dzJ5GAlY9VGMCe7mfQDl8qnTuuW7efwUqHKZEDDQA/viewform?usp=sf_link",
      name: "Singh-Ads",
    },
    { href: "/", name: "About Us" },
    { href: "/", name: "Blog" },
    { href: "/", name: "License" },
  ],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
