import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUpdatedPrice } from "services/contract";
import { getMyTicket } from "services/contract";
import { getIpfsData } from "services/contract";
import { getBuyTicketDetails } from "services/contract";
import { getCreator } from "services/contract";
import { useWallet } from "store/wallet";
import { useMediaQuery } from "react-responsive";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Loader from "loader/loader";
import { useSearchParams } from "react-router-dom";
import { getEventOrganisers } from "services/contract";
import { checkTicketStatus } from "services/contract";
import alreadyUsedLogo from "./alreadyUsedLogo.png";

export default function TicketVerify() {
  const { web3, isWeb3Enabled, address } = useWallet();
  const [searchParams] = useSearchParams();
  const [verified, setVerified] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(true);
  const [verifiedTicketDetails, setVerifiedTicketDetails] = useState({});
  const [quantity, setQuantity] = useState("");
  const [organisersList, setOrganisersList] = useState([]);
  const [ticketUsed, setTicketUsed] = useState(false);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  useEffect(() => {
    verifyCalls();
  }, [web3, address]);

  const verifyCalls = () => {
    if (Object.keys(web3).length != 0 && address != undefined) {
      fetchCreator();
    } else {
      console.log("noData");
    }
  };

  const fetchCreator = async () => {
    setVerificationLoading(true);
    const getCreatorAddress = await getCreator(web3, searchParams.get("tokenId"));
    console.log("creator", getCreatorAddress);

    const getOrganiserRes = await getEventOrganisers(web3, searchParams.get("tokenId"));

    console.log("ORGList", getOrganiserRes);

    setOrganisersList(getOrganiserRes);

    // const organiserListChecker = (item) => {
    //   return item === address;
    // };

    // if (
    //   getCreatorAddress === address ||
    //   organisersList.some((item) => organiserListChecker(item))
    // ) {

    // const organiserListChecker = (item) => {
    //   return item === fakeAdd;
    // };

    // console.log(
    //   "boolean",
    //   fakeOrgList.some((item) => organiserListChecker(item))
    // );

    if (getCreatorAddress === address || getOrganiserRes.includes(address)) {
      console.log("success");

      const ticketStatusData = {
        tokenId: searchParams.get("tokenId"),
        userAddress: searchParams.get("buyerWalletAddress"),
      };

      const putTicketStatus = await checkTicketStatus(ticketStatusData);

      console.log("TICKETSTATUS", putTicketStatus);
      if (putTicketStatus.success === false) {
        setTicketUsed(true);
      }

      const myTicket = await getMyTicket(
        web3,
        searchParams.get("buyerWalletAddress"),
        searchParams.get("tokenId")
      );
      console.log("myTicket", myTicket);
      setQuantity(myTicket);

      const myTicketDetails = await getBuyTicketDetails(web3, searchParams.get("tokenId"));
      console.log("myTicketDetails", myTicketDetails);

      if (myTicketDetails.metaDataUri) {
        const otherEventsData = await getIpfsData(myTicketDetails.metaDataUri);
        // const updatedPrice = await getUpdatedPrice(web3, myTicketDetails.tokenId);
        myTicketDetails["name"] = otherEventsData.name;
        myTicketDetails["category"] = otherEventsData.category;
        // myTicketDetails["description"] = otherEventsData.description;
        myTicketDetails["photo"] = otherEventsData.photo;
        myTicketDetails["venue"] = otherEventsData.venue;
        // myTicketDetails["blockChain"] = otherEventsData.blockChain;
        // myTicketDetails["latestPrice"] = updatedPrice;
      }

      setVerified(true);
      setVerifiedTicketDetails(myTicketDetails);
      setVerificationLoading(false);
    } else {
      console.log("error");
      setVerified(false);
      setVerificationLoading(false);
    }
  };

  return (
    <>
      {verificationLoading ? (
        <MDBox
          sx={{
            height: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </MDBox>
      ) : (
        <>
          {verified ? (
            <Card
              sx={{
                marginTop: "2rem",
                marginRight: isTabletOrMobile ? "5%" : "10%",
                marginLeft: isTabletOrMobile ? "5%" : "10%",
                marginBottom: "5rem",
                padding: "0.5rem",
              }}
            >
              <Grid container sx={{}}>
                <Grid
                  item
                  container
                  xs={12}
                  md={3}
                  sx={{ justifyContent: "center", marginBottom: { xs: "1rem", md: "0" } }}
                >
                  <MDBox
                    sx={{
                      height: "300px",
                      width: "300px",
                    }}
                  >
                    <img
                      src={verifiedTicketDetails.photo}
                      style={{ width: "100%", height: "100%" }}
                      alt="logo"
                    />
                  </MDBox>
                </Grid>
                <Grid
                  sx={{
                    alignItems: "center",
                  }}
                  item
                  container
                  xs={12}
                  md={5}
                >
                  <Grid
                    item
                    container
                    rowGap={2}
                    xs={12}
                    sx={{ paddingLeft: "1rem", textAlign: "start" }}
                  >
                    <Grid item xs={6}>
                      <MDTypography fontWeight="bold">Name :</MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <MDTypography fontWeight="regular">{verifiedTicketDetails.name}</MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <MDTypography fontWeight="bold">Event date :</MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <MDTypography fontWeight="regular">
                        {verifiedTicketDetails.eventDate}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <MDTypography fontWeight="bold">Category :</MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <MDTypography>{verifiedTicketDetails.category}</MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <MDTypography fontWeight="bold">Token Id :</MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <MDTypography>{searchParams.get("tokenId")}</MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <MDTypography fontWeight="bold">Venue :</MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <MDTypography>{verifiedTicketDetails.venue}</MDTypography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid sx={{}} item container xs={12} md={4}>
                  <Grid item container sx={{ alignItems: "center" }}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                      <MDTypography fontSize="150px" color="button" lineHeight="0">
                        <CheckCircleIcon fontSize="inherit" sx={{ color: "inherit" }} />
                      </MDTypography>
                    </Grid>
                  </Grid>
                  <Grid item container sx={{ alignItems: "start" }}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MDTypography color="white" fontWeight="bold" fontSize="40px">
                        {quantity}
                      </MDTypography>
                      <MDTypography color="white" fontSize="20px">
                        Tickets
                      </MDTypography>
                    </Grid>
                  </Grid>
                  {ticketUsed && (
                    <img
                      src={alreadyUsedLogo}
                      style={{ width: "200px", position: "absolute", right: 0, top: 0 }}
                      alt="alreadyUsedLogo"
                    />
                  )}
                </Grid>
              </Grid>
            </Card>
          ) : (
            <Grid
              container
              xs={12}
              md={6}
              sx={{
                margin: "auto",
                // border: "1px solid red",
                justifyContent: "center",
                alignItems: "center",
                padding: "1rem",
              }}
            >
              <Card
                sx={{
                  marginTop: "5rem",
                  width: "100%",
                  height: "250px",
                  // marginRight: isTabletOrMobile ? "5%" : "30%",
                  // marginLeft: isTabletOrMobile ? "5%" : "30%",
                  // marginBottom: "5rem",
                  // padding: "0.5rem",
                }}
              >
                <MDBox sx={{ textAlign: "center" }}>
                  <MDTypography fontSize="150px" color="error" lineHeight="0">
                    <CancelIcon fontSize="inherit" sx={{ color: "error" }} />
                  </MDTypography>
                  <MDTypography>Connected Wallet is not the</MDTypography>
                  <MDTypography>owner of this ticket</MDTypography>
                </MDBox>
              </Card>
            </Grid>
          )}
        </>
      )}
    </>
  );
}
